import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { FaRegCircle, FaRegDotCircle } from "react-icons/fa";
import { BiTransfer } from "react-icons/bi";
import { PaystackButton } from "react-paystack";
import {
  registrationSelectForm,
  updateForm,
} from "../features/registrationSlice";
import { Header } from "../components/Header";
import { url } from "../constants/variables";
import { format } from "currency-formatter";
import { appSelectPaymentType } from "../features/appSlice";
import { donationSelectAmount } from "../features/donationSlice";
import { toast } from "react-hot-toast";
import LoadingComp from "../components/LoadingComp";
import UploadModal from "../components/UploadModal";

const Payment = () => {
  const regForm = useSelector(registrationSelectForm);
  const paymentType = useSelector(appSelectPaymentType);
  const amount = useSelector(donationSelectAmount);
  const [option, setOption] = useState(null);
  const [registering, setRegistering] = useState(false);
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [upload, setUpload] = useState(false);
  const [fullName, setFullName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const proceed = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setRegistering(true);
    if (paymentType === "registration") {
      ReactGA.event({
        category: "Successful registration payment",
        action: "Payment",
        label: `Payment upload`,
        value: `name: ${regForm.name}, member: ${regForm.topping}`,
      });
      axios
        .post(`${url}/register`, {
          ...regForm,
          payment: { name: fullName, url: image },
        })
        .then((res) => {
          setRegistering(false);
          dispatch(updateForm(res.data));
          navigate("/welcome");
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
        });
    } else {
      ReactGA.event({
        category: "Successful donation payment",
        action: "Donation Payment",
        label: `Donation`,
        value: `Donation of ${amount}`,
      });
      navigate("/donate");
      toast.success("Thank you for your donation");
    }
  };

  const widget = (file) => {
    const url = "https://api.cloudinary.com/v1_1/dquwxckdi/auto/upload";
    // e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", "752325154748448");
    formData.append("upload_preset", "t9evtqgc");
    formData.append("timestamp", new Date());
    // formData.append("signature", signData.signature);
    // formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
    formData.append("folder", "LPAdmin");

    setUploading(true);
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        const respose = JSON.parse(data);
        setImage(respose.url);
        setUploading(false);
      });
  };

  const handleFileInput = (e) => {
    // handle validations
    const file = e.target.files[0];
    if (!file) return;
    widget(file);
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: regForm?.email ? regForm.email : "account@labourpartylagos.org",
    amount: paymentType === "registration" ? 100000 : amount, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_live_4f3f9d022b98085ca273cd23ee5d1ba2209de499",
  };

  const handlePaystackSuccessAction = async (reference) => {
    try {
      ReactGA.event({
        category: "registration payment",
        action: "Paystack payment",
        label: `Paystack Button`,
        value: `name: ${regForm.name}, member: ${regForm.topping}`,
      });
      if (paymentType === "registration") {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setRegistering(true);
        axios
          .post(`${url}/register`, {
            ...regForm,
            payment: { name: regForm.name, url: "paystack" },
          })
          .then((res) => {
            setRegistering(false);
            dispatch(updateForm(res.data));
            navigate("/welcome");
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.message);
          });
      } else if (paymentType === "donation") {
        navigate("/donate");
        toast.success("Thank you for your donation!");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handlePaystackCloseAction = () => {
    console.log("closed");
  };

  const componentProps = {
    ...config,
    text: `Pay ₦${format(
      paymentType === "registration" ? 1000 : parseInt(amount) / 100,
      "NG"
    )}`,
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="payment">
      <Header />
      {registering && <LoadingComp />}
      <UploadModal
        upload={upload}
        setUpload={setUpload}
        proceed={proceed}
        uploading={uploading}
        handleFileInput={handleFileInput}
        image={image}
        fullName={fullName}
        setFullName={setFullName}
      />
      <div className="payment__box">
        <div
          className="pay__header"
          style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}
        >
          Select Payment Option
        </div>
        <div
          className={`payment__option ${
            option === "paystack"
              ? "payment__option--show"
              : "payment__option--hide"
          }`}
        >
          <div
            className="payment__header"
            onClick={() => {
              setOption("paystack");
            }}
          >
            <div className="payment__description">
              {option === "paystack" ? (
                <FaRegDotCircle
                  className={`payment__icon ${
                    option === "paystack" ? "payment__icon--active" : ""
                  }`}
                />
              ) : (
                <FaRegCircle
                  className={`payment__icon ${
                    option === "paystack" ? "payment__icon--active" : ""
                  }`}
                />
              )}
              <div>
                <div>debit & credit cards</div>
              </div>
            </div>
            <div className="payment__image">
              <img src="/images/payment.png" />
            </div>
          </div>
          {
            option === "paystack" && (
              <div
                className={`payment__body ${
                  option === "paystack"
                    ? "payment__body--show"
                    : "payment__body--hide"
                }`}
              >
                {paymentType === "registration" && (
                  <>
                    <p>Membership Card - N500.</p>
                    <p>Processing & Delivery Fee - N500.</p>
                    <p>Total Fee - N1,000</p>
                  </>
                )}
                {paymentType === "donation" && (
                  <>
                    <p>Thank you for your N{parseInt(amount) / 100} donation</p>
                  </>
                )}
                <PaystackButton className="payment__btn" {...componentProps} />
              </div>
            )
            // <div
            //   className={`payment__body ${
            //     option === "paystack"
            //       ? "payment__body--show"
            //       : "payment__body--hide"
            //   }`}
            // >
            //   <p style={{ color: "#d18f17", padding: "10px 0" }}>
            //     Unavailable, Please Use Direct Transfer
            //   </p>
            // </div>
          }
        </div>
        <div
          className={`payment__option ${
            option === "bank"
              ? "payment__option--show"
              : "payment__option--hide"
          }`}
        >
          <div
            className="payment__header"
            onClick={() => {
              setOption("bank");
            }}
          >
            <div className="payment__description">
              {option === "bank" ? (
                <FaRegDotCircle
                  className={`payment__icon ${
                    option === "bank" ? "payment__icon--active" : ""
                  }`}
                />
              ) : (
                <FaRegCircle
                  className={`payment__icon ${
                    option === "bank" ? "payment__icon--active" : ""
                  }`}
                />
              )}
              <div>
                <div>direct bank transfer</div>
              </div>
            </div>
            <div className="payment__image">
              <BiTransfer size={40} />
            </div>
          </div>
          <div
            className={`payment__body ${
              option === "bank" ? "payment__body--show" : "payment__body--hide"
            }`}
          >
            <div className="row-align-around">
              <label htmlFor="accName">account name</label>
              <input
                readOnly
                id="accName"
                value={"LABOUR PARTY (LP) LAGOS STATE"}
              />
            </div>
            <div className="row-align-around">
              <label htmlFor="accNum">account number</label>
              <input readOnly id="accNum" value={"1224871298"} />
            </div>
            <div className="row-align-around">
              <label htmlFor="bank">Bank</label>
              <input readOnly id="bank" value={"Zenith Bank"} />
            </div>
            {paymentType === "registration" && (
              <div className="payment__information">
                <p>Membership Card - N500.</p>
                <p>Processing & Delivery Fee - N500.</p>
                <p>Total Fee - N1,000</p>
              </div>
            )}
            {paymentType === "donation" && (
              <>
                <p>Thank you for your N{parseInt(amount) / 100} donation</p>
              </>
            )}
            <div className="payment__upload">
              <button
                className="payment__btn"
                onClick={() => {
                  setUpload(true);
                }}
              >
                Upload Payment Receipt
              </button>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      {paymentType === "registration" && (
        <div
          style={{
            fontSize: "14px",
            color: "red",
            textAlign: "center",
            padding: "10px",
          }}
        >
          *Note: Membership Registration At Your Ward Costs Only N500.
          <p>Please Note That Registration Fees Are Non-Refundable.</p>
        </div>
      )}
    </div>
  );
};

export default Payment;
