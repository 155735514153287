import React from "react";
import { Header } from "../components/Header";
import Hero1 from "../components/Hero1";
import { HealthBG } from "../asset";
import { SiPinboard } from "react-icons/si";

const Health = () => {
  return (
    <div className="health">
      <Header />
      <Hero1 bg={HealthBG} />
      <div className="health__content">
        <h2 className="health__title health__title--main">
          Comprehensive Health Policy: A Blueprint for Affordable and Accessible
          Healthcare in Lagos State.
        </h2>
        <div className="health__wrapper">
          <SiPinboard className="health__icon health__icon--pin" />
          <h2 className="health__subtitle health__subtitle--main">
            Introduction
          </h2>
        </div>
        <p className="health__text">
          This health policy document outlines the Labour Party's commitment to
          improving the healthcare system in Lagos State. Our vision is to
          ensure accessible, affordable, and high-quality healthcare services
          for all residents. This policy encompasses key areas such as
          healthcare infrastructure, primary care, disease prevention,
          healthcare workforce, and health education. Through effective
          implementation, we aim to create a healthier and more prosperous
          society in Lagos State.
        </p>
        <div className="health__wrapper">
          <SiPinboard className="health__icon health__icon--pin" />
          <h2 className="health__subtitle">
            Challenges of the Healthcare System in Lagos State
          </h2>
        </div>
        <p className="health__text">
          <b>Limited healthcare infrastructure: </b> Lagos State faces
          challenges related to inadequate healthcare infrastructure, including
          a shortage of hospitals, clinics, and primary healthcare centers. This
          leads to overcrowding, long waiting times, and compromised access to
          healthcare services, particularly in densely populated areas.
        </p>
        <p className="health__text">
          <b>Insufficient healthcare workforce: </b> There is a shortage of
          healthcare professionals, including doctors, nurses, and midwives, in
          Lagos State. This scarcity results in an overwhelming burden on
          existing healthcare workers, impacting the quality and timeliness of
          care provided to patients.
        </p>
        <p className="health__text">
          <b>Inequitable distribution of healthcare services: </b> Healthcare
          services in Lagos State are disproportionately concentrated in urban
          areas, leaving rural and underserved communities with limited access
          to essential healthcare. This exacerbates health disparities and
          increases the burden of disease in marginalized populations.
        </p>
        <p className="health__text">
          <b>High healthcare costs: </b> The cost of healthcare services,
          including consultations, medications, and hospitalization, can be
          prohibitively high for many residents of Lagos State. This financial
          barrier prevents individuals and families from seeking timely and
          appropriate care, leading to delayed treatment and poorer health
          outcomes.
        </p>
        <p className="health__text">
          <b>Insufficient focus on preventive care: </b> The healthcare system
          in Lagos State primarily focuses on curative care, with limited
          emphasis on preventive measures. This approach results in a higher
          burden of preventable diseases and a significant strain on the
          healthcare system, both in terms of resources and finances.
        </p>
        <p className="health__text">
          <b>Emergency service and first responders: </b> Emergency services and
          first responders play a critical role in ensuring the safety and
          well-being of residents during medical emergencies in Lagos State.
          However, there are challenges that need to be addressed. Currently,
          the emergency response infrastructure is inadequate, with a shortage
          of ambulances, trained personnel, and communication systems. This
          leads to delayed response times and compromised care.
        </p>
        <h2 className="health__title">Our Solution</h2>
        <div className="health__wrapper">
          <SiPinboard className="health__icon health__icon--pin" />
          <h2 className="health__subtitle">Healthcare Infrastructure</h2>
        </div>
        <p className="health__text">
          <b>Establishing and upgrading healthcare facilities: </b> We will
          prioritize the construction and renovation of healthcare centers,
          hospitals, and clinics, particularly in underserved areas. This
          includes increasing the number of beds, improving medical equipment,
          and ensuring access to essential medicines.
        </p>
        <p className="health__text">
          <b>Strengthening referral systems: </b> We will develop an efficient
          referral system to ensure seamless transfers between primary,
          secondary, and tertiary healthcare facilities. This will enhance the
          continuity of care and improve patient outcomes.
        </p>
        <p className="health__text">
          <b>Emphasizing technology integration: </b> We will invest in the
          integration of technology, such as electronic health records (EHRs),
          telemedicine, and mobile health applications, to improve healthcare
          delivery, data management, and patient access to medical advice.
        </p>
        <div className="health__wrapper">
          <SiPinboard className="health__icon health__icon--pin" />
          <h2 className="health__subtitle">
            Primary Care and Preventive Services
          </h2>
        </div>
        <p className="health__text">
          <b>Primary healthcare revitalization: </b> We will prioritize the
          establishment and renovation of primary healthcare centers across
          Lagos State, ensuring they are adequately staffed, equipped, and
          supplied with essential medicines.
        </p>
        <p className="health__text">
          <b>Disease prevention and health promotion: </b> We will launch
          comprehensive public health campaigns targeting prevalent diseases in
          Lagos State, including malaria, HIV/AIDS, tuberculosis, and
          non-communicable diseases. These campaigns will focus on education,
          early detection, and preventive measures.
        </p>
        <p className="health__text">
          <b>Maternal and child health: </b> We will strengthen maternal and
          child health services, including antenatal and postnatal care,
          immunization programs, family planning services, and nutrition
          education. Our goal is to reduce maternal and infant mortality rates
          and improve the overall well-being of women and children.
        </p>
        <div className="health__wrapper">
          <SiPinboard className="health__icon health__icon--pin" />
          <h2 className="health__subtitle">Healthcare Workforce</h2>
        </div>
        <p className="health__text">
          <b>Training and recruitment: </b> We will prioritize the training and
          recruitment of healthcare professionals, including doctors, nurses,
          midwives, and allied health workers. This will involve partnerships
          with educational institutions to increase the number of healthcare
          graduates and create a robust workforce.
        </p>
        <p className="health__text">
          <b>Incentives and retention: </b> We will implement attractive
          incentives, including competitive salaries, career development
          opportunities, and improved working conditions, to attract and retain
          qualified healthcare professionals within the public sector.
        </p>
        <p className="health__text">
          <b>Collaborative partnerships: </b> We will foster partnerships with
          professional associations, unions, and international organizations to
          leverage their expertise and support in capacity-building, knowledge
          exchange, and improving healthcare service delivery.
        </p>
        <div className="health__wrapper">
          <SiPinboard className="health__icon health__icon--pin" />
          <h2 className="health__subtitle">Health Education and Awareness</h2>
        </div>
        <p className="health__text">
          <b>School health programs: </b> We will introduce comprehensive health
          education programs in schools to promote healthy behaviors, disease
          prevention, and mental well-being among students. These programs will
          include sexual and reproductive health education, nutrition education,
          and substance abuse prevention.
        </p>
        <p className="health__text">
          <b>Community health outreach: </b> We will organize regular community
          health outreach programs to raise awareness, provide health
          screenings, and facilitate access to healthcare services in remote and
          underserved areas.
        </p>
        <p className="health__text">
          <b>Health literacy campaigns: </b> We will invest in health literacy
          campaigns to ensure that residents have access to accurate and
          reliable health information. This will empower individuals to make
          informed decisions about their health and well-being.
        </p>
        <div className="health__wrapper">
          <SiPinboard className="health__icon health__icon--pin" />
          <h2 className="health__subtitle">Implementation and Monitoring</h2>
        </div>
        <p className="health__text">
          <b>Allocating adequate budgetary resources to healthcare: </b> We will
          ensure the allocation of a significant portion of the state budget to
          healthcare, including the construction and maintenance of healthcare
          infrastructure, training and recruitment of healthcare professionals,
          and disease prevention programs.
        </p>
        <p className="health__text">
          <b>Establishing monitoring and evaluation mechanisms: </b> We will
          develop robust monitoring and evaluation mechanisms to track the
          progress of healthcare initiatives, assess their impact, and make
          data-driven decisions for continuous improvement.
        </p>
        <p className="health__text">
          <b>Stakeholder engagement: </b> We will actively engage healthcare
          professionals, community leaders, civil society organizations, and
          other relevant stakeholders in the planning, implementation, and
          evaluation of healthcare policies. Their input and feedback will be
          valuable in addressing the unique healthcare needs of different
          communities within Lagos State.
        </p>
        <p className="health__text">
          <b>Public-private partnerships: </b> We will explore opportunities for
          public-private partnerships to leverage resources and expertise from
          the private sector. These partnerships can enhance service delivery,
          infrastructure development, and technological advancements in
          healthcare.
        </p>
        <p className="health__text">
          <b>Regular policy review: </b> We will conduct regular policy reviews
          to ensure the effectiveness and relevance of our health policies. This
          will involve gathering feedback from stakeholders, analyzing health
          data, and incorporating new evidence-based practices into our
          policies.
        </p>
        <div className="health__wrapper">
          <SiPinboard className="health__icon health__icon--pin" />
          <h2 className="health__subtitle">Improve Emergency Services</h2>
        </div>
        <p className="health__text">
          <b>Strengthened emergency response infrastructure: </b> Investment in
          emergency response infrastructure is essential to enhance the capacity
          to handle emergency situations effectively. This includes increasing
          the number of well-equipped ambulances, establishing emergency call
          centers, and improving communication systems to facilitate rapid and
          coordinated response.
        </p>
        <p className="health__text">
          <b>Enhanced training and capacity building: </b> Continuous training
          and capacity building programs for emergency medical personnel and
          first responders should be prioritized. This will ensure that they
          have the necessary skills, knowledge, and equipment to provide
          immediate and appropriate emergency care.
        </p>
        <p className="health__text">
          <b>Expansion of emergency care facilities: </b> It is important to
          expand the availability of emergency care facilities across Lagos
          State, particularly in underserved areas. This includes equipping
          healthcare facilities with emergency departments and ensuring they are
          staffed adequately with trained professionals.
        </p>
        <p className="health__text">
          <b>Integration of emergency services: </b> Effective integration and
          coordination among emergency service providers, healthcare facilities,
          and law enforcement agencies are crucial for efficient emergency
          response. Establishing protocols, communication channels, and
          streamlined transfer mechanisms will improve the overall coordination
          and effectiveness of emergency services.
        </p>
        <p className="health__text">
          <b>Public awareness and education: </b> Public awareness campaigns can
          educate residents about the appropriate utilization of emergency
          services and how to respond during emergencies. This will help in
          reducing unnecessary emergency room visits and ensuring that emergency
          services are available for those in genuine need.
        </p>
        <div className="health__wrapper">
          <SiPinboard className="health__icon health__icon--pin" />
          <h2 className="health__subtitle">
            The Need for a State Public Health Insurance Policy
          </h2>
        </div>
        <p className="health__text">
          <b>Enhanced financial protection: </b> A state public health insurance
          policy would provide financial protection to residents of Lagos State,
          ensuring that they can access healthcare services without facing
          catastrophic healthcare costs. It would alleviate the burden of
          out-of-pocket expenses and promote equitable access to healthcare for
          all individuals, regardless of their socioeconomic status.
        </p>
        <p className="health__text">
          <b>Improved healthcare access: </b> Public health insurance would
          facilitate improved access to healthcare services for all residents,
          including those in rural and underserved areas. It would ensure that
          individuals have coverage for essential medical treatments, preventive
          services, and medications, thereby reducing disparities in healthcare
          access across the state.
        </p>
        <p className="health__text">
          <b>Strengthened healthcare infrastructure: </b> A public health
          insurance policy can generate revenue that can be invested in
          expanding and upgrading healthcare infrastructure in Lagos State. This
          includes the construction and renovation of healthcare facilities,
          procurement of medical equipment, and ensuring the availability of
          essential medicines, thereby improving the overall quality of
          healthcare services.
        </p>
        <p className="health__text">
          <b>Focus on preventive care: </b> Public health insurance can
          incentivize a shift towards preventive care, as it encourages regular
          health check-ups, screenings, and vaccinations. By investing in
          preventive measures, the burden of disease can be reduced, leading to
          improved health outcomes and cost savings in the long run.
        </p>
        <p className="health__text">
          <b>Sustainable healthcare financing: </b> A state public health
          insurance policy can establish a sustainable financing mechanism for
          healthcare in Lagos State. By pooling resources and implementing
          risk-sharing mechanisms, the policy can ensure the availability of
          funds for healthcare services and contribute to the long-term
          viability of the healthcare system.
        </p>
        <div className="health__wrapper">
          <SiPinboard className="health__icon health__icon--pin" />
          <h2 className="health__subtitle">Conclusion</h2>
        </div>
        <p className="health__text">
          Implementing a state public health insurance policy in Lagos State is
          crucial to address the challenges faced by the healthcare system. It
          would enhance financial protection, improve healthcare access,
          strengthen healthcare infrastructure, promote preventive care, and
          establish sustainable healthcare financing. By addressing these
          issues, the Labour Party aims to create a comprehensive and inclusive
          healthcare system that prioritizes the well-being of all residents in
          Lagos State. The Labour Party in Lagos State is committed to
          transforming the healthcare system to ensure accessible, affordable,
          and high-quality healthcare services for all residents. This
          comprehensive health policy addresses healthcare infrastructure,
          primary care, disease prevention, healthcare workforce, and health
          education. By implementing this policy and engaging stakeholders, we
          aim to create a healthier and more prosperous society in Lagos State.
          Through regular monitoring and evaluation, we will continuously
          improve our healthcare initiatives to meet the evolving needs of the
          population.
        </p>
      </div>
    </div>
  );
};

export default Health;
