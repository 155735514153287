import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDQY3G0AVJYulHZRNICdUpsSo2k57qcMQw",
  authDomain: "phone-validation-d4a64.firebaseapp.com",
  projectId: "phone-validation-d4a64",
  storageBucket: "phone-validation-d4a64.appspot.com",
  messagingSenderId: "243004101561",
  appId: "1:243004101561:web:ddb2b44a5315822869dc68",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
