import { Suspense, useEffect, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga";
import { About, Excos, Extra, Home, Login } from "./pages";
import { ErrorBoundary } from "../src/error/errorBoundary";
import { Loading } from "./components/Loading";
import Payment from "./pages/Payment";
import Donation from "./pages/Donation";
import Economy from "./pages/Economy";
import Education from "./pages/Education";
import People from "./pages/People";
import Health from "./pages/Health";

const TRACKING_ID = "G-F6ES8WN1CM";
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => window.scrollTo(0, 0), [window]);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/membership"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Login />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Home />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/payment"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Payment />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/welcome"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Extra />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/about"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <About />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/economy"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Economy />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/education"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Education />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/health"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Health />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/people"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <People />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/donate"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Donation />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
