import React from "react";
import { Header } from "../components/Header";
import Hero1 from "../components/Hero1";
import { PeopleBG } from "../asset";
import { SiPinboard } from "react-icons/si";

const People = () => {
  return (
    <div className="people">
      <Header />
      <Hero1 bg={PeopleBG} />
      <div className="people__content">
        <h2 className="people__title people__title--main">
          People-Centric Policy: Labour Party’s Vision for Lagos State
        </h2>
        <div className="people__wrapper">
          <SiPinboard className="people__icon people__icon--pin" />
          <h2 className="people__subtitle people__subtitle--main">
            Introduction
          </h2>
        </div>
        <p className="people__text">
          The People Policy Document of the Labour Party Lagos State outlines
          the principles and guidelines that govern the treatment, engagement,
          and welfare of Lagosians. We recognize that our responsibility as a
          political party extends to the well-being and empowerment of the
          people of Lagos State. This policy aims to promote inclusivity,
          fairness, and respect for all individuals residing in Lagos State.
        </p>
        <div className="people__wrapper">
          <SiPinboard className="people__icon people__icon--pin" />
          <h2 className="people__subtitle">
            Equal Opportunity, Non-Discrimination, and Police Brutality
          </h2>
        </div>
        <p className="people__text">
          The Labour Party Lagos State is committed to promoting equal
          opportunities for all Lagosians, regardless of their race, ethnicity,
          gender, sexual orientation, disability, age, religion, or
          socio-economic background.
        </p>
        <p className="people__text">
          Discrimination, harassment, or victimization of any kind, including
          but not limited to verbal, physical, or online abuse, will not be
          tolerated within the party or any activities conducted on behalf of
          the party.
        </p>
        <div className="people__wrapper">
          <SiPinboard className="people__icon people__icon--pin" />
          <h2 className="people__subtitle">
            Collaboration for Transparency, Accountability, and Respect for
            Human Rights
          </h2>
        </div>
        <p className="people__text">
          The party condemns police brutality in all forms and is committed to
          advocating for justice, accountability, and reforms within the law
          enforcement agencies to ensure the safety and protection of all
          Lagosians.
        </p>
        <p className="people__text">
          The Labour Party Lagos State recognizes the need to work
          collaboratively with security stakeholders, including law enforcement
          agencies, to create a system that upholds the rights of Lagosians and
          puts an end to the issues of police harassment.
        </p>
        <p className="people__text">
          We will actively engage in partnerships and dialogue with relevant
          authorities to establish mechanisms that promote transparency,
          accountability, and respect for human rights within law enforcement
          agencies.
        </p>
        <p className="people__text">
          The party will advocate for the implementation of measures such as
          robust internal oversight, external independent review mechanisms, and
          regular reporting of police activities to ensure transparency and
          accountability.
        </p>
        <p className="people__text">
          We will support the training and education of law enforcement officers
          on human rights standards, ethical conduct, de-escalation techniques,
          and cultural sensitivity to foster a respectful and rights-conscious
          approach in their interactions with Lagosians.
        </p>
        <p className="people__text">
          The Labour Party Lagos State will actively support and collaborate
          with civil society organizations, community leaders, and human rights
          advocates to monitor and address instances of police harassment and
          ensure that the voices of affected individuals are heard and justice
          is served.
        </p>
        <div className="people__wrapper">
          <SiPinboard className="people__icon people__icon--pin" />
          <h2 className="people__subtitle">Inclusivity and Diversity</h2>
        </div>
        <p className="people__text">
          The Labour Party Lagos State values and celebrates the diversity of
          Lagosians, recognizing that a pluralistic society strengthens our
          collective progress and prosperity.
        </p>
        <p className="people__text">
          Efforts will be made to ensure that underrepresented groups have a
          voice in policy-making processes, and their perspectives are
          considered in decision-making.
        </p>
        <p className="people__text">
          The party will actively seek to engage with communities across Lagos
          State, fostering dialogue, understanding, and collaboration.
        </p>
        <div className="people__wrapper">
          <SiPinboard className="people__icon people__icon--pin" />
          <h2 className="people__subtitle">Welfare and Development</h2>
        </div>
        <p className="people__text">
          The Labour Party Lagos State is committed to the welfare and
          development of Lagosians, striving to create an environment that
          enables individuals to thrive and reach their full potential.
        </p>
        <p className="people__text">
          Policies and initiatives will be designed to address social and
          economic inequalities, promote access to quality education,
          healthcare, affordable housing, and employment opportunities.
        </p>
        <p className="people__text">
          The party will support initiatives that foster entrepreneurship, skill
          development, and job creation to enhance the economic well-being of
          Lagosians.
        </p>
        <div className="people__wrapper">
          <SiPinboard className="people__icon people__icon--pin" />
          <h2 className="people__subtitle">
            Civic Engagement and Participation:
          </h2>
        </div>
        <p className="people__text">
          The Labour Party Lagos State encourages active civic engagement and
          participation by all Lagosians.
        </p>
        <p className="people__text">
          Platforms will be created to enable meaningful dialogue, where the
          voices and concerns of Lagosians are heard and integrated into policy
          formulation.
        </p>
        <p className="people__text">
          The party will promote transparency, accountability, and good
          governance, ensuring that the interests of Lagosians are upheld.
        </p>
        <div className="people__wrapper">
          <SiPinboard className="people__icon people__icon--pin" />
          <h2 className="people__subtitle">Collaboration and Partnerships</h2>
        </div>
        <p className="people__text">
          The Labour Party Lagos State recognizes the importance of
          collaboration and partnerships with civil society organizations,
          community groups, and other stakeholders in driving positive change
          for Lagosians.
        </p>
        <p className="people__text">
          The party will seek to establish collaborative relationships to
          leverage resources, expertise, and knowledge for the benefit of the
          people of Lagos State.
        </p>
        <div className="people__wrapper">
          <SiPinboard className="people__icon people__icon--pin" />
          <h2 className="people__subtitle">Conclusion</h2>
        </div>
        <p className="people__text">
          The People Policy Document of the Labour Party Lagos State underscores
          our commitment to promoting equality, inclusivity, and the well-being
          of Lagosians. By adhering to these principles, we aim to foster a
          society where every individual has the opportunity to flourish, and
          collective progress is achieved. We condemn police brutality and stand
          firm in our advocacy for justice, accountability, and reforms within
          law enforcement agencies to ensure the safety and protection of all
          Lagosians. The Labour Party Lagos State will work collaboratively with
          security stakeholders to create a system that upholds the rights of
          Lagosians and puts an end to the issues of police harassment,
          promoting transparency, accountability, and respect for human rights.
          We will actively engage in dialogue and partnerships with law
          enforcement agencies, civil society organizations, community leaders,
          and human rights advocates to implement comprehensive reforms and
          monitor progress.
        </p>
        <p className="people__text">
          Through community engagement initiatives, we will foster positive
          relationships between Lagosians and law enforcement officers,
          promoting mutual understanding and cooperation. Our commitment to
          training and professional development will ensure that law enforcement
          personnel possess the necessary skills and knowledge to serve and
          protect our communities effectively.
        </p>
        <p className="people__text">
          Transparency and accountability are fundamental principles of our
          approach. We will advocate for the implementation of measures such as
          body-worn cameras, independent oversight bodies, and robust internal
          disciplinary procedures. By regularly reporting police activities and
          incidents of misconduct, we will maintain public trust and confidence
          in law enforcement agencies.
        </p>
        <p className="people__text">
          The Labour Party Lagos State recognizes that creating a safe and just
          society requires collective effort. We will actively seek the input
          and involvement of Lagosians, particularly those affected by police
          harassment, in shaping policies and driving reforms. By amplifying
          their voices, we aim to ensure that justice is served and that the
          rights and dignity of all Lagosians are protected.
        </p>
      </div>
    </div>
  );
};

export default People;
