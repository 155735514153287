import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GrFormClose } from "react-icons/gr";
import { AiOutlineMenu } from "react-icons/ai";
import { Button } from "./Button";
import { logo } from "../asset";

export const Header = () => {
  const [toggle, setToggle] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(false);

  const handleToggle = () => setToggle((prev) => !prev);
  const handleSubmenuToggle = () => setShowSubmenu((prev) => !prev);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      {/* desktop */}
      <header className="shadow sticky z-50 top-0 left-0 hidden lg:flex items-center px-10 bg-gray-50 h-[80px] w-full font-semibold capitalize justify-between">
        <Link to="/">
          <img className="h-7" src={logo} alt="" />
        </Link>
        <div className="flex items-center space-x-10 ">
          <Link to="/">
            <p className="cursor-pointer hover:text-gray-500">Home</p>
          </Link>
          <Link to="/about">
            <p className="cursor-pointer hover:text-gray-500">About Us</p>
          </Link>
          <div
            onMouseEnter={handleSubmenuToggle}
            onMouseLeave={handleSubmenuToggle}
            className="relative"
          >
            <p className="cursor-pointer hover:text-gray-500 mb-1">Policy</p>
            {showSubmenu && (
              <div className="absolute top-full left-1/2 transform -translate-x-1/2 p-2 bg-white shadow rounded-lg w-40">
                <div className="flex flex-col space-y-2">
                  <Link to="/economy">
                    <p className="cursor-pointer text-center hover:text-gray-500 mt-1 mb-1">
                      Economy
                    </p>
                  </Link>
                  <Link to="/education">
                    <p className="cursor-pointer text-center hover:text-gray-500 mt-1 mb-1">
                      Education
                    </p>
                  </Link>
                  <Link to="/health">
                    <p className="cursor-pointer text-center hover:text-gray-500 mt-1 mb-1">
                      Health Care
                    </p>
                  </Link>
                  <Link to="/people">
                    <p className="cursor-pointer text-center hover:text-gray-500 mt-1 mb-1">
                      People
                    </p>
                  </Link>
                </div>
              </div>
            )}
          </div>
          <Link to="/donate">
            <p className="cursor-pointer hover:text-gray-500">Donate</p>
          </Link>
          <Link to="/membership">
            <Button
              onClick={() => console.log("btn")}
              title="Become a member"
              color="gray"
            />
          </Link>
        </div>
      </header>

      {/* mobile */}
      <div className="shadow flex items-center px-10 bg-gray-50 h-[80px] w-full font-semibold capitalize justify-between lg:hidden sticky z-50 top-0 left-0">
        <Link to="/">
          <img src={logo} className="h-7" alt="" />
        </Link>
        {toggle ? (
          <GrFormClose
            onClick={handleToggle}
            className="text-2xl font-bold text-gray-500 cursor-pointer"
          />
        ) : (
          <AiOutlineMenu
            onClick={handleToggle}
            className="text-2xl font-bold text-gray-500 cursor-pointer"
          />
        )}
        {toggle && (
          <header className="absolute flex flex-col items-center justify-center p-10 space-y-5 text-white bg-gray-900 rounded-lg top-16 right-10 ">
            <Link to="/about">
              <p className="cursor-pointer hover:text-gray-500">Our Team</p>{" "}
            </Link>
            <Link to="/membership">
              <p className="cursor-pointer hover:text-gray-500">
                Become a member
              </p>{" "}
            </Link>
            <a href="/#policies">
              <p className="cursor-pointer hover:text-gray-500 mb-1">Policy</p>
            </a>
            <Link to="/donate">
              <p className="cursor-pointer hover:text-gray-500">Donate</p>{" "}
            </Link>
          </header>
        )}
      </div>
    </>
  );
};
