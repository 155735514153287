import React from "react";
import { Header } from "../components/Header";
import Hero1 from "../components/Hero1";
import { EconomyBG } from "../asset";
import { SiPinboard } from "react-icons/si";

const Economy = () => {
  return (
    <div className="economy">
      <Header />
      <Hero1 bg={EconomyBG} title="Lagos Labour Party Economic Policy:" />
      <div className="economy__content">
        <h2 className="economy__title economy__title--main">
          Lagos State Labour Party Economic Policy: Building a Strong and
          Inclusive Economy for All
        </h2>
        <div className="economy__wrapper">
          <SiPinboard className="economy__icon economy__icon--pin" />
          <h2 className="economy__subtitle economy__subtitle--main">
            Introduction
          </h2>
        </div>
        <p className="economy__text">
          The Labour Party of Lagos State envisions a thriving and inclusive
          economy that benefits all residents. Our economic policy aims to
          foster sustainable growth, create quality jobs, reduce inequality, and
          promote social welfare.
        </p>
        <div className="economy__wrapper">
          <SiPinboard className="economy__icon economy__icon--pin" />
          <h2 className="economy__subtitle">
            Current Challenges of Lagos Economy
          </h2>
        </div>
        <p className="economy__text">
          <b>Population Growth and Urbanization: </b>Lagos State is experiencing
          rapid population growth and urbanization, leading to increased
          pressure on infrastructure, housing, and public services. The
          expanding population creates challenges in providing adequate
          healthcare, education, transportation, and sanitation facilities.
        </p>
        <p className="economy__text">
          <b>Unemployment and Underemployment: </b>Lagos faces high levels of
          unemployment and underemployment, particularly among the youth. The
          lack of sufficient job opportunities relative to the growing labor
          force contributes to social and economic challenges, including poverty
          and inequality.
        </p>
        <p className="economy__text">
          <b>Informal Economy: </b>A significant portion of economic activities
          in Lagos operates in the informal sector, which hampers productivity
          and limits the potential for revenue generation. Informal businesses
          often face challenges accessing finance, technology, and markets,
          hindering their growth and integration into the formal economy.
        </p>
        <p className="economy__text">
          <b>Inadequate Infrastructure: </b>The state's infrastructure,
          including transportation networks, energy supply, and water resources,
          faces significant shortcomings. Traffic congestion, power outages,
          inadequate water supply, and substandard road networks impede economic
          productivity and pose challenges for businesses and residents.
        </p>
        <p className="economy__text">
          <b>Inadequate Infrastructure: </b>Lagos experiences stark income
          disparities and social inequalities. A considerable portion of the
          population struggles with limited access to basic amenities, such as
          quality healthcare, education, and affordable housing. Addressing
          these inequalities and ensuring social welfare is crucial for
          sustainable development and inclusive growth.
        </p>
        <p className="economy__text">
          <b>Environmental Degradation: </b>Lagos faces environmental
          challenges, including pollution, waste management, and climate change
          impacts. Rapid urbanization and industrial activities contribute to
          air and water pollution, while improper waste management practices
          affect public health and the environment. Climate change threatens the
          city with increased flooding and other extreme weather events.
        </p>
        <p className="economy__text">
          <b>Skills Gap and Education Quality: </b>The quality of education and
          the skills gap are significant challenges in Lagos. There is a need to
          align the education system with the skills required in the job market,
          particularly in emerging sectors. The lack of quality education
          opportunities limits the potential for innovation, entrepreneurship,
          and economic diversification.
        </p>
        <p className="economy__text">
          <b>Access to Finance: </b>Access to affordable finance remains a
          challenge for businesses and entrepreneurs, particularly for small and
          medium-sized enterprises. Limited access to credit and cumbersome
          bureaucratic processes inhibit business growth, innovation, and job
          creation.
        </p>
        <p className="economy__text">
          <b>Double Taxation: </b>One significant challenge faced by businesses
          in Lagos is the issue of double taxation, compounded by the problem of
          excessive and illegal taxation from various sources. In addition to
          the legitimate taxes imposed by federal and state authorities,
          businesses in Lagos often find themselves inundated with unnecessary
          and illegal taxes demanded by hoodlums, local government authorities
          (LGAs), and unofficial agents. These unofficial taxes add an extra
          financial burden on businesses, increase operational costs, and create
          an atmosphere of uncertainty and insecurity. The cumulative effect of
          double taxation and unauthorized levies further reduces profitability,
          discourages investment, and hampers economic growth and
          entrepreneurship in Lagos State.
        </p>
        <div className="economy__wrapper">
          <SiPinboard className="economy__icon economy__icon--pin" />
          <h2 className="economy__subtitle">Our Solution</h2>
        </div>
        <p className="economy__text">
          By implementing the following strategies, we aim to enhance the
          economic well-being of Lagos State.
        </p>
        <p className="economy__text">
          <b>Centralized Tax Monitoring System: </b>To address the challenge of
          excessive and illegal taxation faced by businesses in Lagos, a
          technological solution can be implemented in the form of a centralized
          tax monitoring system. This system would utilize digital tools and
          platforms to track and record all tax-related transactions, making the
          process transparent and accountable. By implementing a secure digital
          ledger, the system can ensure the authenticity and integrity of tax
          records, eliminating the possibility of unauthorized agents imposing
          illegal taxes. Additionally, businesses can submit their tax
          information electronically, reducing the scope for corruption and
          providing a clear audit trail. The centralized tax monitoring system
          would enable real-time monitoring of tax payments, allowing
          authorities to identify and take action against any instances of
          unauthorized taxation. This technological solution would enhance tax
          compliance, promote a fair and equitable tax system, and create a more
          favorable business environment for entrepreneurs and investors in
          Lagos State.
        </p>
        <p className="economy__text">
          <b>Economic Diversification: </b>Lagos State has long relied on
          sectors such as trade and services. We recognize the need to diversify
          the economy to reduce dependence on these sectors. Our government will
          invest in emerging industries, such as technology, renewable energy,
          creative arts, and manufacturing. We will facilitate the establishment
          of innovation hubs, incubators, and vocational training centers to
          promote entrepreneurship and job creation.
        </p>
        <p className="economy__text">
          <b>Small and Medium Enterprises (SME) Support: </b>SMEs form the
          backbone of the Lagos State economy. We will introduce policies to
          support their growth and sustainability. This includes providing
          affordable loans, creating business development programs, simplifying
          registration processes, and offering tax incentives. By fostering a
          favorable business environment, we will empower SMEs to thrive, create
          more jobs, and contribute to economic growth.
        </p>
        <p className="economy__text">
          <b>Infrastructure Development: </b>A robust infrastructure network is
          crucial for economic growth. The Labour Party will prioritize
          infrastructure development in Lagos State. We will invest in
          transportation systems, including road networks, railways, and water
          transportation, to enhance connectivity and facilitate the movement of
          goods and services. Additionally, we will improve electricity supply,
          water resources management, and internet connectivity to support
          businesses and attract investors.
        </p>
        <p className="economy__text">
          <b>Human Capital Development: </b>Investing in human capital is
          essential for sustainable economic growth. We will focus on improving
          the quality of education and vocational training in Lagos State. This
          includes modernizing school infrastructure, upgrading curriculum, and
          expanding access to technical and vocational training programs. By
          equipping residents with the skills and knowledge required for the job
          market, we will enhance productivity, reduce unemployment, and bridge
          the skills gap.
        </p>
        <p className="economy__text">
          <b>Social Welfare and Poverty Alleviation: </b> We are committed to
          addressing poverty and promoting social welfare. We will implement
          targeted poverty alleviation programs, such as conditional cash
          transfers, skills acquisition programs, and social insurance schemes.
          Additionally, we will prioritize affordable housing projects, improve
          healthcare facilities, and provide social protection for vulnerable
          groups. These measures will ensure that the benefits of economic
          growth are shared equitably.
        </p>
        <p className="economy__text">
          <b>Sustainable Development and Environmental Protection: </b>We
          recognize the importance of sustainable development and environmental
          protection. Our economic policies will be designed to promote green
          initiatives, renewable energy adoption, waste management, and
          conservation efforts. We will encourage industries to adopt
          environmentally friendly practices and support research and
          development in clean technologies. By embracing sustainable practices,
          we can foster long-term economic prosperity while preserving our
          environment.
        </p>
        <div className="economy__wrapper">
          <SiPinboard className="economy__icon economy__icon--pin" />
          <h2 className="economy__subtitle economy__subtitle--main">
            Conclusion
          </h2>
        </div>
        <p className="economy__text">
          The Lagos Labour Party's economic policy is designed to create a
          robust, inclusive, and sustainable economy. By diversifying sectors,
          supporting SMEs, eliminating illegal taxation, investing in
          infrastructure and human capital, promoting social welfare, and
          prioritizing sustainability, we aim to build a prosperous Lagos State
          where every resident has access to quality jobs, social protection,
          and a higher standard of living. Through collaboration and effective
          governance, we believe that our economic policy will drive positive
          change and secure a better future for all Lagosians.
        </p>
      </div>
    </div>
  );
};

export default Economy;
