import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Header } from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  donationSelectAmount,
  updateDonationAmount,
} from "../features/donationSlice";
import { Link } from "react-router-dom";
import { updatePaymentType } from "../features/appSlice";

const Donation = () => {
  const amount = useSelector(donationSelectAmount);

  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="donation">
      <Header />
      <div className="donation__wrapper">
        <div className="donation__overlay"></div>
        <div className="donation__card">
          <div
            className="donation__header"
            style={{ fontSize: "26px", color: "red" }}
          >
            Your donation helps fund our campaign and operation. Thank you for
            your support!
          </div>
          <div className="donation__options">
            {/* <div
              className={`donation__box ${amount === "50000" ? "active" : ""}`}
              onClick={() => {
                dispatch(updateDonationAmount("50000"));
              }}
            >
              ₦500
            </div> */}
            <div
              className={`donation__box ${amount === "100000" ? "active" : ""}`}
              onClick={() => {
                dispatch(updateDonationAmount("1000"));
              }}
            >
              ₦1,000
            </div>
            <div
              className={`donation__box ${amount === "200000" ? "active" : ""}`}
              onClick={() => {
                dispatch(updateDonationAmount("2000"));
              }}
            >
              ₦2,000
            </div>
            <div
              className={`donation__box ${amount === "300000" ? "active" : ""}`}
              onClick={() => {
                dispatch(updateDonationAmount("3000"));
              }}
            >
              ₦3,000
            </div>
            <div
              className={`donation__box ${amount === "400000" ? "active" : ""}`}
              onClick={() => {
                dispatch(updateDonationAmount("4000"));
              }}
            >
              ₦4,000
            </div>
            <div
              className={`donation__box ${amount === "500000" ? "active" : ""}`}
              onClick={() => {
                dispatch(updateDonationAmount("5000"));
              }}
            >
              ₦5,000
            </div>
            <div className="donation__input">
              <span>₦</span>
              <input
                placeholder="Other amount"
                min={500}
                type="number"
                onChange={(e) => {
                  dispatch(updateDonationAmount(e.target.value));
                }}
              />
            </div>
          </div>
          <Link
            onClick={() => {
              ReactGA.event({
                category: "Donation",
                action: "Initiated donation process",
                label: `Donation Button`,
                value: `Donation of ${amount}`,
              });
              dispatch(updatePaymentType("donation"));
            }}
            to={parseInt(amount) < "100000" ? "/donate" : "/payment"}
            className="donation__btn"
          >
            Donate
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Donation;
