import React from "react";
import { Button } from "./Button";
import { Link } from "react-router-dom";

export const Donate = () => {
  return (
    <div className="flex items-center space-x-8 bg-gray-800 justify-center h-[200px] p-4 ">
      <h3 className="text-xl lg:text-4xl text-white font-black">
        Would you like to become one of our donors?
      </h3>
      <Link
        className="capitalize rounded py-2 px-4 w-max hover:animate-bounce ease-in-out duration-150 transition-all hover:text-white outline-none border-none shadow-sm"
        to="/donate"
        style={{ backgroundColor: "gray" }}
      >
        Donate
      </Link>
    </div>
  );
};
