export const LGALIST = [
  {
    id: 1,
    name: "Alimosho",
  },
  {
    id: 2,
    name: "Apapa",
  },
  {
    id: 21,
    name: "Agege",
  },
  {
    id: 3,
    name: "Ifako-Ijaye",
  },
  {
    id: 4,
    name: "Ikeja",
  },
  {
    id: 5,
    name: "Kosofe",
  },
  {
    id: 6,
    name: "Mushin",
  },
  {
    id: 7,
    name: "Oshodi-Isolo",
  },
  {
    id: 8,
    name: "Shomolu",
  },
  {
    id: 9,
    name: "Eti-Osa",
  },
  {
    id: 10,
    name: "Lagos Island",
  },
  {
    id: 11,
    name: "Lagos Mainland",
  },
  {
    id: 12,
    name: "Surulere",
  },
  {
    id: 13,
    name: "Ojo",
  },
  {
    id: 14,
    name: "Ajeromi-Ifelodun",
  },
  {
    id: 15,
    name: "Amuwo-Odofin",
  },
  {
    id: 16,
    name: "Badagry",
  },
  {
    id: 17,
    name: "Ikorodu",
  },
  {
    id: 18,
    name: "Ibeju-Lekki",
  },
  {
    id: 19,
    name: "Epe",
  },
];

export const WardList = [
  {
    id: 146,
    name: "Abomiti",
  },
  {
    id: 147,
    name: "Abule-egba/aboru/meiran/alagbado",
  },
  {
    id: 148,
    name: "Abule-okuta/ilaje/bariga",
  },
  {
    id: 149,
    name: "Adekunle Vill./adeniyi Jones/ogba",
  },
  {
    id: 150,
    name: "Adeniran/ogunsanya",
  },
  {
    id: 151,
    name: "Ado/langbasa/badore",
  },
  {
    id: 152,
    name: "Afolabi Alasia Str. And Environs",
  },
  {
    id: 153,
    name: "Aga/ijimu",
  },
  {
    id: 154,
    name: "Agarawu/obadina",
  },
  {
    id: 155,
    name: "Agbala",
  },
  {
    id: 156,
    name: "Agbotikuyo/dopemu",
  },
  {
    id: 157,
    name: "Agbowa",
  },
  {
    id: 158,
    name: "Agbowa Ikosi",
  },
  {
    id: 159,
    name: "Agboyi I",
  },
  {
    id: 160,
    name: "Agboyi Ii",
  },
  {
    id: 161,
    name: "Ago Hausa",
  },
  {
    id: 162,
    name: "Ago Owu",
  },
  {
    id: 163,
    name: "Aguda",
  },
  {
    id: 164,
    name: "Agura/iponmi",
  },
  {
    id: 165,
    name: "Airport/onipetesi/inilekere",
  },
  {
    id: 166,
    name: "Ajaganabe",
  },
  {
    id: 167,
    name: "Ajah/sangotedo",
  },
  {
    id: 168,
    name: "Ajangbadi",
  },
  {
    id: 169,
    name: "Ajao Estate",
  },
  {
    id: 170,
    name: "Ajara",
  },
  {
    id: 171,
    name: "Ajegunle/akinde/animashaun",
  },
  {
    id: 172,
    name: "Ajido",
  },
  {
    id: 173,
    name: "Akinhanmi/cole",
  },
  {
    id: 174,
    name: "Alaba Oro",
  },
  {
    id: 175,
    name: "Alade",
  },
  {
    id: 176,
    name: "Alagomeji",
  },
  {
    id: 177,
    name: "Alakara",
  },
  {
    id: 178,
    name: "Alakuko/kollington",
  },
  {
    id: 179,
    name: "Alausa/oregun/olusosun",
  },
  {
    id: 180,
    name: "Amuwo",
  },
  {
    id: 181,
    name: "Amuwo-odofin Housing Estate, Mile 2",
  },
  {
    id: 182,
    name: "Anifowoshe/ikeja",
  },
  {
    id: 183,
    name: "Anikantamo",
  },
  {
    id: 184,
    name: "Anthony/ajao Estate/mende/maryland",
  },
  {
    id: 185,
    name: "Apa",
  },
  {
    id: 186,
    name: "Apapa I (marine Rd. & Environs)",
  },
  {
    id: 187,
    name: "Apapa Ii (liverpool Rd. And Environs)",
  },
  {
    id: 188,
    name: "Apapa Iii (creek Rd. Tincan/snake Island",
  },
  {
    id: 189,
    name: "Apapa Iv (pelewura Crescent And Environs)",
  },
  {
    id: 190,
    name: "Awhanjigoh",
  },
  {
    id: 191,
    name: "Awodi-ora",
  },
  {
    id: 192,
    name: "Ayobo/ijon Village (camp David)",
  },
  {
    id: 193,
    name: "Babalosa",
  },
  {
    id: 194,
    name: "Babalosa/idi-araba",
  },
  {
    id: 195,
    name: "Baiyeku/oreta",
  },
  {
    id: 196,
    name: "Bajulaiye",
  },
  {
    id: 197,
    name: "Coker",
  },
  {
    id: 198,
    name: "Darocha",
  },
  {
    id: 199,
    name: "Egbe/agodo",
  },
  {
    id: 200,
    name: "Egbeda/alimosho",
  },
  {
    id: 201,
    name: "Eiyekole",
  },
  {
    id: 202,
    name: "Ejirin",
  },
  {
    id: 203,
    name: "Epetedo",
  },
  {
    id: 204,
    name: "Erikorodu",
  },
  {
    id: 205,
    name: "Etegbin",
  },
  {
    id: 206,
    name: "Etita/ebode",
  },
  {
    id: 207,
    name: "Fagba/akute Road",
  },
  {
    id: 208,
    name: "Festac 1",
  },
  {
    id: 209,
    name: "Festac II",
  },
  {
    id: 210,
    name: "Festac Iii",
  },
  {
    id: 211,
    name: "Fola Agoro/bajulaiye/igbari-akoka",
  },
  {
    id: 212,
    name: "Gaskiya & Environs",
  },
  {
    id: 213,
    name: "Gbagada Phase I Obanikoro/pedro",
  },
  {
    id: 214,
    name: "Gbagada Phase Ii /bariga/apelehin",
  },
  {
    id: 215,
    name: "Glover/ebute Metta",
  },
  {
    id: 216,
    name: "Gra/police Barracks",
  },
  {
    id: 217,
    name: "Iba",
  },
  {
    id: 218,
    name: "Ibeju I",
  },
  {
    id: 219,
    name: "Ibereko",
  },
  {
    id: 220,
    name: "Ibeshe",
  },
  {
    id: 221,
    name: "Ibeshe",
  },
  {
    id: 222,
    name: "Ibonwon",
  },
  {
    id: 223,
    name: "Idi-araba",
  },
  {
    id: 224,
    name: "Idi-oro/odi-olowu",
  },
  {
    id: 225,
    name: "Idimu/isheri Olofin",
  },
  {
    id: 226,
    name: "Idoluwo",
  },
  {
    id: 227,
    name: "Idumota/oke",
  },
  {
    id: 228,
    name: "Iduntafa",
  },
  {
    id: 229,
    name: "Ifako/soluyi",
  },
  {
    id: 230,
    name: "Igando/egan",
  },
  {
    id: 231,
    name: "Igbaja/stadium",
  },
  {
    id: 232,
    name: "Igbobi/fadeyi",
  },
  {
    id: 233,
    name: "Igbogbo I",
  },
  {
    id: 234,
    name: "Igbogbo Ii",
  },
  {
    id: 235,
    name: "Igbologun",
  },
  {
    id: 236,
    name: "Ijaiye/agbado/kollington",
  },
  {
    id: 237,
    name: "Ijaiye/ojokoro",
  },
  {
    id: 238,
    name: "Ijanikin",
  },
  {
    id: 239,
    name: "Ijaye",
  },
  {
    id: 240,
    name: "Ijede Ii",
  },
  {
    id: 241,
    name: "Ijede J",
  },
  {
    id: 242,
    name: "Ijegun",
  },
  {
    id: 1,
    name: "Onipanu",
  },
  {
    id: 2,
    name: "Oniwaya/papa-uku",
  },
  {
    id: 3,
    name: "Oriba/ladaba",
  },
  {
    id: 4,
    name: "Orile",
  },
  {
    id: 5,
    name: "Orile Agege/oko Oba",
  },
  {
    id: 6,
    name: "Orile-oshodi",
  },
  {
    id: 7,
    name: "Orimedu I",
  },
  {
    id: 8,
    name: "Orugbo",
  },
  {
    id: 9,
    name: "Oshodi/bolade",
  },
  {
    id: 10,
    name: "Otto/iddo",
  },
  {
    id: 11,
    name: "Owode Onirin/ajegunle/odo-ogun",
  },
  {
    id: 12,
    name: "Oworonshoki",
  },
  {
    id: 13,
    name: "Oyadiran Estate/abule-oja",
  },
  {
    id: 14,
    name: "Oyewole/papa Ashafa",
  },
  {
    id: 15,
    name: "Oyingbo Market/ebute Metta",
  },
  {
    id: 16,
    name: "P1, (iwerekun I)",
  },
  {
    id: 17,
    name: "Palmgrove/ijebutedo",
  },
  {
    id: 18,
    name: "Pamada/abule-egba",
  },
  {
    id: 19,
    name: "Papa-ajao",
  },
  {
    id: 20,
    name: "Pleasure/oke-odo",
  },
  {
    id: 21,
    name: "Poka",
  },
  {
    id: 22,
    name: "Popo-aguda",
  },
  {
    id: 23,
    name: "Popo-oba",
  },
  {
    id: 24,
    name: "Posukoh",
  },
  {
    id: 25,
    name: "S,2a (siriwon/igbekodo Ii)",
  },
  {
    id: 26,
    name: "S1, (lekki I)",
  },
  {
    id: 27,
    name: "S2, (siriwon/igbekodo I)",
  },
  {
    id: 28,
    name: "Sabo",
  },
  {
    id: 29,
    name: "Sandgrouse",
  },
  {
    id: 30,
    name: "Sari And Environs",
  },
  {
    id: 31,
    name: "Satellite",
  },
  {
    id: 32,
    name: "Shasha/akowonjo",
  },
  {
    id: 33,
    name: "Shitta/ogunlana Drive",
  },
  {
    id: 34,
    name: "Sogunle",
  },
  {
    id: 35,
    name: "Sogunle/alasia",
  },
  {
    id: 36,
    name: "Tabon Tabon/oko Oba",
  },
  {
    id: 37,
    name: "Tafi",
  },
  {
    id: 38,
    name: "Temidire I",
  },
  {
    id: 39,
    name: "Temidire Ii",
  },
  {
    id: 40,
    name: "Tolu",
  },
  {
    id: 41,
    name: "Victoria Island I",
  },
  {
    id: 42,
    name: "Victoria Island Ii",
  },
  {
    id: 43,
    name: "Wasimi/opebi/allen",
  },
  {
    id: 44,
    name: "Wilmer",
  },
  {
    id: 45,
    name: "Yaba/igbobi",
  },
  {
    id: 46,
    name: "Yaba/ojuelegba",
  },
  {
    id: 47,
    name: "Ijeshatedo",
  },
  {
    id: 48,
    name: "Ijora-oloye",
  },
  {
    id: 49,
    name: "Iju Isaga",
  },
  {
    id: 50,
    name: "Iju-obawole",
  },
  {
    id: 51,
    name: "Ikate",
  },
  {
    id: 52,
    name: "Ikoga",
  },
  {
    id: 53,
    name: "Ikosi Ketu/mile 12/agiliti/maidan",
  },
  {
    id: 54,
    name: "Ikotun/ijegun",
  },
  {
    id: 55,
    name: "Ikoyi I",
  },
  {
    id: 56,
    name: "Ikoyi Ii",
  },
  {
    id: 57,
    name: "Ilado/eti-osa And Environs",
  },
  {
    id: 58,
    name: "Ilaje/akoka",
  },
  {
    id: 59,
    name: "Ilara",
  },
  {
    id: 60,
    name: "Ilasamaja",
  },
  {
    id: 61,
    name: "Ilasan Housing Estate",
  },
  {
    id: 62,
    name: "Ilogbo",
  },
  {
    id: 63,
    name: "Ilogbo-araromi",
  },
  {
    id: 64,
    name: "Iloro/onipetesi",
  },
  {
    id: 65,
    name: "Ilupeju",
  },
  {
    id: 66,
    name: "Ilupeju Industrial Estate",
  },
  {
    id: 67,
    name: "Ilupesi",
  },
  {
    id: 68,
    name: "Imota 1",
  },
  {
    id: 69,
    name: "Imota Ii",
  },
  {
    id: 70,
    name: "Ipaja North",
  },
  {
    id: 71,
    name: "Ipaja South",
  },
  {
    id: 72,
    name: "Ipakodo",
  },
  {
    id: 73,
    name: "Ipodo/seriki Aro",
  },
  {
    id: 74,
    name: "Iponri Housing Estate/eric Moore",
  },
  {
    id: 75,
    name: "Irede",
  },
  {
    id: 76,
    name: "Irewe",
  },
  {
    id: 77,
    name: "Isale Odo",
  },
  {
    id: 78,
    name: "Isale-agbede",
  },
  {
    id: 79,
    name: "Isale/idimangoro",
  },
  {
    id: 80,
    name: "Ise/igbogun",
  },
  {
    id: 81,
    name: "Isele I",
  },
  {
    id: 82,
    name: "Isele Ii",
  },
  {
    id: 83,
    name: "Isele Iii",
  },
  {
    id: 84,
    name: "Ishagatedo",
  },
  {
    id: 85,
    name: "Isheri-olowo-ira/shangisha/magodo Phase I & Ii",
  },
  {
    id: 86,
    name: "Isiu",
  },
  {
    id: 87,
    name: "Isolo",
  },
  {
    id: 88,
    name: "Itire",
  },
  {
    id: 89,
    name: "Itire",
  },
  {
    id: 90,
    name: "Itoikin",
  },
  {
    id: 91,
    name: "Iwaya",
  },
  {
    id: 92,
    name: "Iwerekun Ii",
  },
  {
    id: 93,
    name: "Iworo Gbanko",
  },
  {
    id: 94,
    name: "Iya-afin",
  },
  {
    id: 95,
    name: "Kayode/fadeyi",
  },
  {
    id: 96,
    name: "Keke",
  },
  {
    id: 97,
    name: "Keta-east",
  },
  {
    id: 98,
    name: "Ketu/alapere/agidi/orisigun/kosofe/ajelogo/akanimodo",
  },
  {
    id: 99,
    name: "Kirikiri",
  },
  {
    id: 100,
    name: "Lad-lak/bariga",
  },
  {
    id: 101,
    name: "Lafiaji/ebute",
  },
  {
    id: 102,
    name: "Lagbade",
  },
  {
    id: 103,
    name: "Layeni",
  },
  {
    id: 104,
    name: "Lekki Ii",
  },
  {
    id: 105,
    name: "Lekki/ikate And Environs",
  },
  {
    id: 106,
    name: "Mafoluku",
  },
  {
    id: 107,
    name: "Mafowoku/pedro",
  },
  {
    id: 108,
    name: "Malu Road And Environs",
  },
  {
    id: 109,
    name: "Maroko/ebute Metta",
  },
  {
    id: 110,
    name: "Mosafejo",
  },
  {
    id: 111,
    name: "Mushin/atewolara",
  },
  {
    id: 112,
    name: "N2, (ibeju Ii)",
  },
  {
    id: 113,
    name: "New Ifako/oyemekun",
  },
  {
    id: 114,
    name: "Obalende",
  },
  {
    id: 115,
    name: "Odomola",
  },
  {
    id: 116,
    name: "Odoragunsin",
  },
  {
    id: 117,
    name: "Ojo Road",
  },
  {
    id: 118,
    name: "Ojo Town",
  },
  {
    id: 119,
    name: "Ojodu/agidingbi/omole",
  },
  {
    id: 120,
    name: "Ojota/ogudu",
  },
  {
    id: 121,
    name: "Oju-oto",
  },
  {
    id: 122,
    name: "Ojuwoye",
  },
  {
    id: 123,
    name: "Oke-afa/ejigbo",
  },
  {
    id: 124,
    name: "Oke-balogun",
  },
  {
    id: 125,
    name: "Oke-ira/aguda",
  },
  {
    id: 126,
    name: "Okekoto",
  },
  {
    id: 127,
    name: "Oko-awo",
  },
  {
    id: 128,
    name: "Oko-baba",
  },
  {
    id: 129,
    name: "Oko-faji",
  },
  {
    id: 130,
    name: "Okokomaiko",
  },
  {
    id: 131,
    name: "Okota",
  },
  {
    id: 132,
    name: "Olaleye Village",
  },
  {
    id: 133,
    name: "Olateju",
  },
  {
    id: 134,
    name: "Old Ifako/karaole",
  },
  {
    id: 135,
    name: "Olodan St. Olojowou St/alh.dogo Olatokunbo St. Iganmu",
  },
  {
    id: 136,
    name: "Olodi",
  },
  {
    id: 137,
    name: "Olorunda/igbaga",
  },
  {
    id: 138,
    name: "Olosun",
  },
  {
    id: 139,
    name: "Olowogbowo/elegbata",
  },
  {
    id: 140,
    name: "Olushi/kakawa",
  },
  {
    id: 141,
    name: "Oluwole",
  },
  {
    id: 142,
    name: "Onigbongbon",
  },
  {
    id: 143,
    name: "Onikan",
  },
  {
    id: 144,
    name: "02, (orimedu Ii)",
  },
  {
    id: 145,
    name: "03, (orimedu Iii)",
  },
];

export const EXCOS = [
  {
    id: 1,
    img: "/images/Chairman1.jpg",
    name: "Adedayo Ekong",
    tilte: "State Chairman",
  },
  {
    id: 2,
    img: "/images/paul  Igene.jpg",
    name: "Paul J.A. Igene",
    tilte: "Deputy State Chairman",
  },
  {
    id: 3,
    img: "/images/Lanre Adenuga.jpg",
    name: "Lanre Adenuga",
    tilte: "Deputy State Chairman",
  },
  {
    id: 4,
    img: "/images/Olatunbunsu Oswald.jpg",
    name: "Dr. Oswald  Olatunbosun",
    tilte: "Deputy State Chairman",
  },
  {
    id: 5,
    img: "/images/Adeola Adebanjo.jpg",
    name: "Engr. Adeola Adebanjo",
    tilte: "Deputy State Chairman",
  },
  {
    id: 6,
    img: "/images/yemi paul ayeni.jpg",
    name: "Yemi Paul Ayeni",
    tilte: "Deputy State Chairman",
  },
  {
    id: 7,
    img: "/images/funke awolowo.jpg",
    name: "Funke Awolowo",
    tilte: "Deputy State Chairman",
  },
  {
    id: 8,
    img: "/images/sam emeka okpala.png",
    name: "Sam Emeka Okpala",
    tilte: "State Secretary",
  },
  {
    id: 9,
    img: "/images/princess emily jude.jpg",
    name: "Princess Emily Jude",
    tilte: "Deputy State Secretary",
  },
  {
    id: 10,
    img: "/android-chrome-192x192.png",
    name: "Adejoke Adegoke",
    tilte: "Deputy State Secretary",
  },
  {
    id: 11,
    img: "/images/bunmi odesanya .jpg",
    name: "Olubunmi Odesanya",
    tilte: "State Publicity Secretary",
  },
  {
    id: 12,
    img: "/images/Seyi Akindele.jpg",
    name: "Seyi Akinde",
    tilte: "State Financial Secretary",
  },
  {
    id: 13,
    img: "/images/jennifer ukachi.jpg",
    name: "Jennifer Ukachi",
    tilte: "State Treasurer",
  },
  {
    id: 14,
    img: "/images/Hajiya Amina Usman_Contact number_ 09078222866.jpg",
    name: "Amina Usman",
    tilte: "State Woman Leader",
  },
  {
    id: 15,
    img: "/android-chrome-192x192.png",
    name: "Folakemi Collins",
    tilte: "State Deputy Woman Leader",
  },
  {
    id: 16,
    img: "/images/_Anthonia Oyeneye.jpg",
    name: "Antonia Oyeneye",
    tilte: "State Deputy Woman Leader",
  },
  {
    id: 17,
    img: "/images/chidinma jane nwoguala.jpg",
    name: "Chidima Jane nwoguala",
    tilte: "State Deputy Woman Leader",
  },
  {
    id: 18,
    img: "/images/okubena abiodun ifeoluwa.jpg",
    name: "Dr Okubena Abiodun Ifeoluwa",
    tilte: "State Youth Leader",
  },
  {
    id: 19,
    img: "/images/osita joseph odobor_.jpg",
    name: "Osita Joseph Odobor",
    tilte: "State Deputy Youth Leader",
  },
  {
    id: 20,
    img: "/images/zibili mary.jpg",
    name: "Zibily Mary",
    tilte: "State Deputy Youth Leader",
  },
  {
    id: 21,
    img: "/images/olushola olarewaju junior.jpg",
    name: "Olushola Olarewaju Junior",
    tilte: "State Deputy Youth Leader",
  },
  {
    id: 22,
    img: "/images/plus omodaratan.jpg",
    name: "Pius Omodaratan",
    tilte: "State Deputy Youth Leader",
  },
  {
    id: 23,
    img: "/images/Folashade Kassim.jpg",
    name: "Folashade Kassim",
    tilte: "State Organizing Secretary",
  },
  {
    id: 24,
    img: "/images/ebuka morah.jpg",
    name: "Ebuka Morah",
    tilte: "State Auditor",
  },
  {
    id: 25,
    img: "/images/Folusho Leigh.jpg",
    name: "Barr. Foluso Leigh",
    tilte: "State Legal Adviser",
  },
];

export const WardList2 = [
  {
    id: 1,
    lga: "Alimosho",
    name: [
      "Shasha/Akowonjo",
      "Egbeda/Alimosho",
      "Ipaja North",
      "Ipaja South",
      "Ayobo/Ijon village (Camp David)",
      "Pleasure/Oke-Odo",
      "Abule-Egba/Aboru/Meiran/Alagbado",
      "Idimu/Isheri Olofin",
      "Ikotun/Ijegun",
      "Egbe/Agodo",
      "Igando/Egan",
      "/android-chrome-192x192.png",
    ],
  },
  {
    id: 2,
    lga: "Agege",
    name: [
      "Isale-Oja/Idi-Mangoro",
      "Iloro/Onipetesi",
      "Oniwaya/Papa-uku",
      "Agbotikuyo/Dopemu",
      "Oyewole/Papa Ashafa",
      "Okekoto",
      "Keke",
      "Darocha",
      "Tabon-Tabon/Oko-Oba",
      "Orile Agege/Oko Oba",
      "Isale Odo",
    ],
  },
  {
    id: 3,
    lga: "Ajeromi-Ifelodun",
    name: [
      "Awodi-Ora",
      "Ojo Road",
      "Layeni",
      "Alaba Oro",
      "Mosafejo",
      "Ago Hausa",
      "Wilmer",
      "Olodi",
      "Tolu",
      "Temidire I",
      "Temidire II",
    ],
  },
  {
    id: 4,
    lga: "Amuwo-Odofin",
    name: [
      "Amuwo Odofin Housing Estate, Mile 2",
      "Festac I",
      "Festac II",
      "Festac III",
      "Kirikiri",
      "Satellite",
      "Amuwo",
      "Ijegun",
      "Irede",
      "Ibeshe",
      "Igbologun",
    ],
  },
  {
    id: 5,
    lga: "Apapa",
    name: [
      "Apapa I",
      "Apapa II",
      "Apapa III",
      "Apapa IV",
      "Afolabi Alasia Street",
      "Ijora-Oloye",
      "Olodan St. Olojowou st/Alh.Dogo Olatokunbo St. Igan",
      "Gaskiya & Environs",
      "Sari & Environs",
      "Malu Road & Environs",
    ],
  },
  {
    id: 6,
    lga: "Badagry",
    name: [
      "Posukoh",
      "Awhanjigoh",
      "Apa",
      "Keta-East",
      "Iworo-Gbanko",
      "Ajido",
      "Ibereko",
      "Ilogbo-Araromi",
      "Ikoga",
      "Ajara",
      "Iya-Afin",
    ],
  },
  {
    id: 7,
    lga: "Epe",
    name: [
      "Ajaganabe",
      "Etita/Ebode",
      "Ise/Igbogun",
      "Lagbade",
      "Oke-Balogun",
      "Popo-Oba",
      "Oriba/Ladaba",
      "Abomiti",
      "Agbowa",
      "Agbowa-Ikosi",
      "Ago-owu",
      "Ejirin",
      "Ibonwon",
      "Ilara",
      "Itoikin",
      "Odomola",
      "Odoragunsin",
      "Orugbo",
      "Poka",
    ],
  },
  {
    id: 8,
    lga: "Eti-Osa",
    name: [
      "Victoria Island I",
      "Victoria Island II",
      "Ilasan Housing Estate",
      "Lekki/Ikate & Environs",
      "Ilado/Eti‐Osa & Environs",
      "Ajah/Sangotedo",
      "Ado/Langbasa/Badore",
      "Ikoyi I",
      "Ikoyi II",
      "Obalende",
    ],
  },
  {
    id: 9,
    lga: "Ibeju-Lekki",
    name: [
      "Ibeju I",
      "N2, (Ibeju II)",
      "Pi, (Iwerekun I)",
      "Iwerekun II",
      "Si, (Lekki I)",
      "Lekki II",
      "Orimedu I",
      "02, (Orimedu II)",
      "03, (Orimedu III)",
      "S2, (Siriwon/Igbekodo I)",
      "S, 2a (Siriwon/Igbekodo II)",
    ],
  },
  {
    id: 10,
    lga: "Ifako-Ijaye",
    name: [
      "Fagba/Akute Road",
      "Iju Isaga",
      "New Ifako/Oyemekun",
      "Old Ifako/Karaole",
      "Iju‐Obawole",
      "Ogba Ijaiye",
      "Alakuko/Kollington",
      "Ijaiye/Agbado/Kollington",
      "Ijaiye/Ojokoro",
      "Ajegunle/Akinde/Animashaun",
      "Pamada/Abule-Egba",
    ],
  },
  {
    id: 11,
    lga: "Ikeja",
    name: [
      "Airport/Onipetesi/Inilekere",
      "Alausa/Oregun/Olusosun",
      "Anifowoshe/Ikeja",
      "Ipodo/Seriki Aro",
      "Ojodu/Agidingbi/Omole",
      "Adekunle Vill./Adeniyi Jones/Ogba",
      "GRA/Police Barracks",
      "Oke Ira/Aguda",
      "Onigbongbon",
      "Wasimi/Opebi/Allenanifowoshe/Ikeja",
    ],
  },
  {
    id: 12,
    lga: "Ikorodu",
    name: [
      "Isele I",
      "Isele II",
      "Isele III",
      "Aga/Ijomu",
      "Ipakodo",
      "Isiu",
      "Agura/Iponmi",
      "Odogunyan",
      "Erikorodo",
      "Agbala",
      "Olorunda/Igbala",
      "Imota I",
      "Imota II",
      "Igbogbo I",
      "Igbogbo II",
      "Baiyeku/Oreta",
      "Ibeshe",
      "Ijede I",
      "Ijede II",
      "Agura/Iponmi",
    ],
  },
  {
    id: 13,
    lga: "Kosofe",
    name: [
      "Oworonshoki",
      "Ifako/Soluyi",
      "Anthony/Ajao Estate/Mende/Maryland",
      "Ojota/Ogudu",
      "Ketu/Alapere/Agidi/Orisigun/Kosofe/Ajelogo/Akanimod",
      "Ikosi Ketu/Mile I2/Agiliti/Maidan",
      "Isheri‐Olowo‐Ira/Shangisha/Magodo Phase I & II",
      "Agboyi I",
      "Agboyi II",
      "Owode Onirin/Ajegunle/Odo‐Ogun",
    ],
  },
  {
    id: 15,
    lga: "Lagos Island",
    name: [
      "Olowogbowo/Elegbata",
      "Oluwole",
      "Idumota/Oke",
      "Oju–Oto",
      "Oko-Awo",
      "Agarawu/Obadina",
      "Iduntafa",
      "Ilupesi",
      "Isale-Agbede",
      "Olosun",
      "Olushi/Kakawa",
      "Popo-Aguda",
      "Anikantamo",
      "Oko- faji",
      "Eiyekole",
      "Onikan",
      "Sandgrouse",
      "Epetedo",
      "Lafiaji/Ebute",
    ],
  },
  {
    id: 16,
    lga: "Lagos Mainland",
    name: [
      "Otto/Iddo",
      "Epetedo",
      "Olaleye village",
      "Makoko/Ebute-Metta",
      "Oyingbo Market/Ebute-Metta",
      "Glover/Ebute Metta",
      "Oko-Baba",
      "Oyadiran Estate/Abule-Oja",
      "Alagomeji",
      "Iwaya",
      "Yaba/Igbobi",
    ],
  },
  {
    id: 17,
    lga: "Mushin",
    name: [
      "Alakara",
      "Idi-oro/Odi-olowo",
      "Babalosa",
      "Ojuwoye",
      "Ilupeju",
      "Olateju",
      "Kayode/Fadeyi",
      "Ilupeju Industrial Estate",
      "Mushin/Atewolara",
      "Papa Ajao",
      "Ilasamaja",
      "Babalosa/Idi-Araba",
      "Itire",
      "Idi-Araba",
    ],
  },
  {
    id: 18,
    lga: "Ojo",
    name: [
      "Ojo Town",
      "Okokomaiko",
      "Ajangbadi",
      "Iba",
      "Sabo",
      "Ijanikin",
      "Ilogbo",
      "Irewe",
      "Taffi",
      "Etegbin",
      "Idoluwo",
    ],
  },
  {
    id: 19,
    lga: "Oshodi-Isolo",
    name: [
      "Oshodi-Bolade",
      "Orile-Oshodi",
      "Mafoluku",
      "Shogunle",
      "Shogunle/Alasia",
      "Isolo",
      "Ajao Estate",
      "Ilasamaja",
      "Okota",
      "Ishagatedo",
      "Oke-Afa/Ejigbo",
    ],
  },
  {
    id: 20,
    lga: "Shomolu",
    name: [
      "Onipanu",
      "Palm grove/Ijebutedo",
      "Alade",
      "Bajulaiye",
      "Igbobi/Fadeyi",
      "Fola Agoro/Bajulaiye/Igbari‐Akoka",
      "Mafoloku/Pedro",
      "ad‐Lak/Bariga",
      "Ilaje/Akoka",
      "Gbagada Phase I/Obanikoro/Pedro",
      "Gbagada Phase II/Bariga/Apelehin",
      "Abule Okuta/Ilaje/Bariga",
    ],
  },
  {
    id: 21,
    lga: "Surulere",
    name: [
      "Yaba/Ojuelegba",
      "Igaja/Stadium",
      "Shitta/Ogunlana Drive",
      "Adeniran Ogunsanya",
      "Iponrin Housing Estate/Eric Moore",
      "Orile",
      "Coker",
      "Aguda",
      "Ijeshatedo",
      "Itire",
      "Ikate",
      "Akinhanmi/Cole",
    ],
  },
];

export const missionData = [
  "Programs and policies aimed at ensuring that national cohesion, peace, security, stability and prosperity shall be the collective concern of all because most Nigerians would be made to realistically feel, believe and see that Nigeria cares, inspires and exists for all.",
  "All processes aimed at entrenching a culture of democracy and development politics",
  "All processes aimed at contesting elections",
  "Transforming the Party into a vanguard for the defense and sustenance of democracy",
];
export const visionData = [
  "Ensure job-led growth and transform the economy of Lagos State.",
  "Overcome underdevelopment and the paradox of poverty in a wealthy state.",
  "Redress the extremes of affluence and deprivation of the masses",
  "Guarantee social justice for all",
  "Cultivate hospitable behaviors and promote our unique diversity.",
  "Build a knowledge-based society through investment in education and skill aquisition.",
];

export const politicalParty = [
  "Accord (A)",
  "Action Alliance (AA)",
  "All Blending Party (ABP)",
  "Advanced Congress Of Democrats (ACD)",
  "Allied Congress Party of Nigeria (ACPN)",
  "Alliance For Democracy (AD)",
  "African Democratic Congress (ADC)",
  "Action Democratic Party (ADP)",
  "AGA All Grassroots Alliance (AGA)",
  "All Grand Alliance Party (AGAP)",
  "Alliance for New Nigeria (ANN)",
  "Abundant Nigeria Renewal PARTY (ANRP)",
  "African Peoples ALLIANCE (APA)",
  "All Progressives Congress (APC)",
  "Advanced Peoples Democratic Alliance (APDA)",
  "All Progressives Grand Alliance (APGA)",
  "African Peoples Party (APP)",
  "Better Nigeria Progressive Party (BNPP)",
  "Coalition for Change (C4C)",
  "Democratic Alternative (DA)",
  "Democratic Peoples Congress (DPC)",
  "Democratic Peoples Party (DPP)",
  "Freedom and Justice Party (FJP)",
  "Fresh Democratic PARTY (FRESH)",
  "Grassroots Development Party of NIGERIA (GDPN)",
  "Green Party of Nigeria (GPN)",
  "Hope Democratic Party (HDP)",
  "Independent Democrats (ID)",
  "Justice Must Prevail Party (JMPP)",
  "Kowa PARTY (KP)",
  "Labour Party (LP)",
  "Legacy Party of Nigeria (LPN)",
  "Mass Action Joint Alliance (MAJA)",
  "Modern Democratic PARTY (MDP)",
  "Masses Movement of Nigeria (MMN)",
  "Mega Progressive Peoples Party (MPPP)",
  "National Action Council (NAC)",
  "National Conscience Party (NCP)",
  "Nigeria Democratic Congress Party (NDCP)",
  "National Democratic Liberty Party (NDLP)",
  "Nigeria Elements Progressive Party (NEPP)",
  "New Generation Party of Nigeria (NGP)",
  "National Interest Party (NIP)",
  "New Nigeria Peoples Party (NNPP)",
  "Nigeria Peoples Congress (NPC)",
  "New Progressive Movement (NPM)",
  "National Rescue Movement (NRM)",
  "National Unity Party (NUP)",
  "People’s Alliance for National Development & Liberty (PANDEL)",
  "People For Democratic Change (PDC)",
  "Peoples Democratic Movement (PDM)",
  "Peoples Democratic Party (PDP)",
  "Progressive Peoples Alliance (PPA)",
  "Providence People’s Congress (PPC)",
  "Peoples Party of Nigeria (PPN)",
  "Peoples Progressive Party (PPP)",
  "Peoples Redemption Party (PRP)",
  "People’s Trust (TP)",
  "Re-build Nigeria Party (RBNP)",
  "Restoration Party of Nigeria (RPN)",
  "Social Democratic Party (SDP)",
  "Sustainable National Party (SNP)",
  "Socialist Party of Nigeria (SPN)",
  "UDP United Democratic Party (UDP)",
  "UPN Unity Party of Nigeria (UPN)",
  "United Progressive Party (UPP)",
  "Young Democratic Party (YDP)",
  "Young Progressive Party (YPP)",
];

export const politicalParty2 = [
  "None",
  "Accord Party (AP)",
  "Action Alliance (AA)",
  "Action Democratic Party (ADP)",
  "African Action Congress (AAC)",
  "African Democratic Congress (ADC)",
  "All Progressives Congress (APC)",
  "All Progressives Grand Alliance (APGA)",
  "Allied Peoples Movement (APM)",
  "Boot Party (BP)",
  "National Rescue Movement (NRM)",
  "New Nigeria Peoples Party (NNPP)",
  "Peoples Democratic Party (PDP)",
  "Peoples Redemption Party (PRP)",
  "Social Democratic Party (SDP)",
  "Young Democratic Party (YDP)",
  "Young Progressive Party (YPP)",
  "Zenith Labour Party (ZLP)",
];
