import { createSlice } from "@reduxjs/toolkit";

const initialState = { paymentType: "registration" };

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updatePaymentType(state, action) {
      state.paymentType = action.payload;
    },
  },
});

export const appSelectPaymentType = (state) => state.app.paymentType;
export const { updatePaymentType } = appSlice.actions;
export default appSlice.reducer;
