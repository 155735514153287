import React, { memo, useEffect } from "react";
import ReactGA from "react-ga";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { useNavigate } from "react-router-dom/dist";
import { EXCOS } from "../constants";
import { Vision } from "../components/Vision";
import { banner } from "../asset";

export const About = memo(() => {
  const navigate = useNavigate();
  const handleNavigate = () => navigate("/excos");

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div>
      <Header />
      <img loading="lazy" className="w-full mb-10" src={banner} alt="" />
      <Vision />
      <h2 className="text-2xl lg:text-4xl font-black text-center mt-20">
        Meet our team
      </h2>
      <p className="text-center text-gray-500">Team of winners and leaders</p>
      {/* <div className="flex items-center justify-end mr-10">
        <Button
          color="gray"
          onClick={handleNavigate}
          title="view excos by ward"
        />
      </div> */}
      <section className="flex flex-wrap items-center justify-evenly my-8">
        {EXCOS.map((info) => (
          <div
            key={info.id}
            className="w-[300px] m-3 rounded-lg group bg-white shadow-md excos__card"
          >
            <div className="excos__card--image">
              <img
                loading="lazy"
                className="w-full rounded-t-lg shadow-sm"
                src={info.img}
                alt={info.name}
              />
            </div>
            <div className="p-4 excos__card--info">
              <h2 className="font-semibold text-sm">{info.name}</h2>
              <p className="text-xs ">{info.tilte}</p>
            </div>
          </div>
        ))}
      </section>

      <Footer />
    </div>
  );
});
