import React, { useEffect } from "react";
import ReactGA from "react-ga";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import { TextInput } from "./TextInput";

const UploadModal = ({
  upload,
  setUpload,
  proceed,
  uploading,
  handleFileInput,
  image,
  fullName,
  setFullName,
}) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="uploadModal">
      <Modal
        show={upload}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Payment Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <TextInput
              placeholder="Upload Transaction Receipt/Screenshot"
              onChange={handleFileInput}
              name="Upload Transaction Receipt"
              accept="image/*"
              type="file"
            />
            {uploading && <span>uploading...</span>}
          </div>
          <input
            onChange={(e) => {
              setFullName(e.target.value);
            }}
            className="uploadModal__input"
            placeholder="Full Name"
          />
          <div>
            <button
              className="payment__btn"
              onClick={() => {
                if (!image) {
                  toast.error("Please upload transaction receipt");
                } else if (!fullName) {
                  toast.error("Please input Full Name");
                } else {
                  setUpload(false);
                  proceed();
                }
              }}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="uploadModal__btn"
            onClick={() => {
              setUpload(false);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadModal;
