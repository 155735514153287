import React from "react";
import { SiPinboard } from "react-icons/si";
import { Header } from "../components/Header";
import Hero1 from "../components/Hero1";
import { EducationBG } from "../asset";

const Education = () => {
  return (
    <div className="education">
      <Header />
      <Hero1 bg={EducationBG} />
      <div className="education__content">
        <h2 className="education__title education__title--main">
          Comprehensive Education Policy Blueprint: Building a Brighter Future
          for Lagos State.
        </h2>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle education__subtitle--main">
            Introduction
          </h2>
        </div>
        <p className="education__text">
          The education policy proposed by the Labour Party Lagos aims to
          provide a comprehensive framework for the development and improvement
          of the education system in Lagos. It recognizes the importance of
          quality education in fostering social mobility, economic growth, and
          sustainable development. The policy focuses on several key areas,
          including access to education, curriculum development, teacher
          training, infrastructure improvement, and educational technology
          integration.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">
            Current Challenges of Lagos education
          </h2>
        </div>
        <p className="education__text">
          <b>Overcrowded Classrooms and Inadequate Infrastructure: </b> Most
          schools in Lagos suffer from limited physical infrastructure,
          including classrooms, libraries, laboratories, and sanitation
          amenities. Similarly, state-owned tertiary institutions in Lagos
          encounter significant infrastructure challenges, including outdated
          facilities, insufficient classrooms, overcrowded lecture halls, and
          inadequate laboratories. These issues significantly hamper the quality
          of teaching, learning, and research activities, thereby impeding
          overall educational progress.
        </p>
        <p className="education__text">
          <b>Insufficient Teacher Training and Curriculum Relevance: </b>
          Inadequate training and professional development opportunities for
          teachers contribute to outdated teaching methods and limited subject
          expertise. Additionally, the existing curriculum may not align
          effectively with the demands of the modern workforce and technological
          advancements. There is a need to regularly review and update the
          curriculum to ensure its relevance and equip students with the
          necessary skills for the job market.
        </p>
        <p className="education__text">
          <b>High Dropout Rates and Inequitable Access: </b> Lagos experiences
          high dropout rates, particularly among disadvantaged students, due to
          factors such as poverty, lack of access to educational resources, and
          the need for child labor. Moreover, there is a significant disparity
          in access to quality education across different regions, with rural
          areas and low-income communities facing limited access to schools,
          trained teachers, and educational resources.
        </p>
        <p className="education__text">
          <b>Limited Integration of Technology and Digital Literacy: </b> Many
          schools in Lagos face challenges in effectively integrating technology
          into classrooms, primarily due to insufficient access to technology
          infrastructure and a lack of digital literacy skills among both
          teachers and students. This hinders the leveraging of technology to
          enhance teaching and learning experiences.
        </p>
        <p className="education__text">
          <b>Inadequate Funding and Limited Research Opportunities: </b> The
          education sector in Lagos often faces budgetary constraints, resulting
          in inadequate funding for infrastructure development, teacher
          training, and educational resources. State-owned universities and
          polytechnics in Lagos struggle with financial challenges, which impact
          infrastructure, research opportunities, and the availability of modern
          teaching resources.
        </p>
        <p className="education__text">
          <b>Limited Collaboration and Stakeholder Engagement: </b> Greater
          collaboration and engagement among the government, private sector,
          communities, and civil society organizations are necessary to
          effectively address the challenges in the education system. Improved
          stakeholder participation can lead to better resource mobilization and
          the implementation of innovative solutions.
        </p>
        <p className="education__text">
          <b>Faculty Recruitment and Retention, and Industry Linkages: </b>
          State-owned universities and polytechnics may face difficulties in
          attracting and retaining qualified faculty members due to budget
          constraints and the allure of better opportunities elsewhere. Limited
          collaboration and partnerships with industries create a gap between
          academia and industry, making it challenging for graduates to
          transition from education to employment.
        </p>
        <p className="education__text">
          <b>Accreditation and Quality Assurance: </b> Some state-owned tertiary
          institutions in Lagos may encounter accreditation issues or struggle
          to maintain and assure the quality of their programs. Ensuring
          effective quality assurance mechanisms and adherence to accreditation
          standards is crucial to safeguarding the value and recognition of
          degrees and diplomas.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">Our Solution</h2>
        </div>
        <p className="education__text">
          Addressing these challenges in state-owned universities, polytechnics,
          and the broader education system necessitates a unified and holistic
          approach. The Labour Party Lagos recognizes the significance of
          bolstering tertiary education in the state and commits to increasing
          investment in infrastructure, research funding, faculty development,
          curriculum reform, and industry collaborations. By ensuring adequate
          funding, improving infrastructure, enhancing curriculum relevance, and
          fostering partnerships with industries, the party aims to cultivate a
          high-quality and globally competitive tertiary education system.
        </p>
        <p className="education__text">
          The Labour Party Lagos understands that tackling these challenges
          requires a comprehensive and well-coordinated strategy. This approach
          entails prioritizing infrastructure development, teacher training,
          curriculum reform, equitable access, technology integration, increased
          funding, and collaborative efforts among all stakeholders. Through the
          following education policy, the party seeks to address these
          challenges and establish a conducive and inclusive education system
          that empowers all students and equips them for a successful future.
        </p>
        <h2 className="education__title">
          Education Policy Framework For Universal Basic Education
        </h2>
        <h2 className="education__title">
          Universale Basic Education Policy Objectives
        </h2>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">Access to Education</h2>
        </div>
        <p className="education__text">
          <b>Universal Basic Education: </b> Prioritize the implementation of
          free and compulsory basic education for all children in Lagos,
          ensuring quality education is accessible to every child.
        </p>
        <p className="education__text">
          <b>School Feeding Program: </b> Establish a sustainable school feeding
          program to improve attendance rates and enhance student nutrition,
          particularly in disadvantaged areas.
        </p>
        <p className="education__text">
          <b>Scholarships and Grants: </b> Introduce scholarships and grants for
          academically gifted students from low-income families, encouraging
          their enrollment in higher education institutions.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">Curriculum Development</h2>
        </div>
        <p className="education__text">
          <b>Review and Update: </b> Conduct a comprehensive review of the
          curriculum to ensure its relevance to the needs of the 21st-century
          workforce, focusing on critical thinking, problem-solving, creativity,
          and digital literacy.
        </p>
        <p className="education__text">
          <b>Vocational Education: </b> Promote the integration of vocational
          and technical education into the curriculum, equipping students with
          practical skills and increasing their employability.
        </p>
        <p className="education__text">
          <b>Entrepreneurship Education: </b> Introduce entrepreneurship
          education at all levels to foster an entrepreneurial mindset,
          innovation, and job creation among students.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">
            Teacher Training and Professional Development
          </h2>
        </div>
        <p className="education__text">
          <b>Continuous Professional Development: </b> Invest in continuous
          professional development programs for teachers, providing
          opportunities to enhance their pedagogical skills, subject knowledge,
          and proficiency in educational technology.
        </p>
        <p className="education__text">
          <b>Recruitment and Retention: </b> Improve teacher recruitment
          processes, incentivize teaching careers, and provide better working
          conditions to attract and retain high-quality educators.
        </p>
        <p className="education__text">
          <b>Teacher Evaluation: </b> Implement a robust teacher evaluation
          system to ensure accountability and maintain teaching standards.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">Infrastructure Improvement</h2>
        </div>
        <p className="education__text">
          <b>School Construction and Renovation: </b> Prioritize the
          construction and renovation of school buildings to accommodate the
          increasing student population and improve the learning environment.
        </p>
        <p className="education__text">
          <b>Technology Infrastructure: </b> Invest in adequate technology
          infrastructure in schools, including computer labs, internet
          connectivity, and digital learning resources, to promote
          technology-enabled education.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">
            Educational Technology Integration
          </h2>
        </div>
        <p className="education__text">
          <b>Digital Learning Resources: </b> Develop and provide digital
          learning resources, including educational software, online libraries,
          and interactive learning platforms, to enhance student engagement and
          learning outcomes.
        </p>
        <p className="education__text">
          <b>E-Learning Platforms: </b> Establish e-learning platforms offering
          online courses, tutorials, and support materials to supplement
          classroom teaching and ensure access to education during emergencies
          or disruptions.
        </p>
        <p className="education__text">
          <b>Technology Skills Training: </b> Introduce comprehensive training
          programs to equip students, teachers, and administrators with
          essential technology skills required for the digital age.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">
            Collaboration and Stakeholder Engagement
          </h2>
        </div>
        <p className="education__text">
          <b>Public-Private Partnerships: </b> Foster collaboration between the
          government, private sector, and civil society organizations to
          mobilize resources, expertise, and innovative approaches in improving
          education.
        </p>
        <p className="education__text">
          <b>Parental and Community Involvement: </b> Encourage active
          involvement of parents and communities in the education system through
          parent-teacher associations, community engagement programs, and
          regular feedback mechanisms.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">Monitoring and Evaluation</h2>
        </div>
        <p className="education__text">
          <b>Data-Driven Decision Making: </b> Establish a robust data
          collection and analysis system to monitor educational progress,
          identify gaps, and inform evidence-based policy-making.
        </p>
        <p className="education__text">
          <b>Independent Quality Assurance: </b> Establish an independent body
          responsible for monitoring and evaluating the quality of education
          delivery, ensuring compliance with standards, and conducting periodic
          assessments.
        </p>
        <h2 className="education__title">
          Education Policy Framework For Tertiary Education
        </h2>
        <h2 className="education__title">Policy Objectives</h2>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">Increased Funding</h2>
        </div>
        <p className="education__text">
          Advocate for increased budgetary allocation to state-owned tertiary
          institutions, ensuring sufficient funding for infrastructure
          development, research initiatives, and faculty recruitment.
        </p>
        <p className="education__text">
          Explore alternative sources of funding, such as public-private
          partnerships, endowments, and grants, to provide additional financial
          support.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle education__subtitle--main">
            Curriculum Reform and Industry Alignment
          </h2>
        </div>
        <p className="education__text">
          Establish a regular review process to update the curriculum, ensuring
          alignment with industry demands and technological advancements.
        </p>
        <p className="education__text">
          Foster collaboration between institutions and industries to develop
          internship programs, industrial attachments, and joint research
          projects, facilitating the transfer of knowledge and skills.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">Infrastructure Development</h2>
        </div>
        <p className="education__text">
          Prioritize infrastructure development, including classrooms,
          laboratories, libraries, and research facilities.
        </p>
        <p className="education__text">
          Seek partnerships with government agencies, private organizations, and
          international donors to secure funds for infrastructure projects.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">
            Faculty Recruitment and Development
          </h2>
        </div>
        <p className="education__text">
          Develop competitive remuneration packages to attract and retain
          qualified faculty members.
        </p>
        <p className="education__text">
          Provide opportunities for faculty professional development, research
          grants, and sabbatical leaves to enhance their expertise and teaching
          quality.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">Research and Development</h2>
        </div>
        <p className="education__text">
          Allocate dedicated funding for research and development activities.
        </p>
        <p className="education__text">
          Encourage collaboration between institutions and industries to address
          local challenges and promote innovation.
        </p>
        <p className="education__text">
          Establish research centers and institutes within the institutions to
          facilitate interdisciplinary research and knowledge exchange.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">
            Strengthening Accreditation and Quality Assurance
          </h2>
        </div>
        <p className="education__text">
          Establish an independent accreditation and quality assurance body to
          ensure adherence to national and international standards.
        </p>
        <p className="education__text">
          Regularly assess and monitor institutions' compliance with quality
          benchmarks and provide support for improvement.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">Industry Partnerships</h2>
        </div>
        <p className="education__text">
          Strengthen ties between state-owned institutions and industries
          through partnerships, advisory boards, and joint initiatives.
        </p>
        <p className="education__text">
          Engage industries in curriculum development, internships, guest
          lectures, and mentorship programs to enhance students' employability.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">
            Collaboration Among Institutions
          </h2>
        </div>
        <p className="education__text">
          Promote collaboration and knowledge sharing among state-owned tertiary
          institutions through consortiums, research networks, and joint
          academic programs.
        </p>
        <p className="education__text">
          Facilitate the exchange of best practices and resources among
          institutions to enhance overall educational quality.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle">Student Support and Welfare</h2>
        </div>
        <p className="education__text">
          Improve student support services, including counseling, career
          guidance, and financial aid programs.
        </p>
        <p className="education__text">
          Enhance student welfare by providing safe and conducive learning
          environments, accommodation facilities, and recreational amenities.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle education__subtitle--main">
            Student Loan Programs
          </h2>
        </div>
        <p className="education__text">
          Introduce student loan programs to provide financial assistance to
          deserving and qualified students who may not have the means to afford
          tertiary education.
        </p>
        <p className="education__text">
          Collaborate with financial institutions, government agencies, and
          private organizations to establish favorable loan terms, including
          low-interest rates and flexible repayment options.
        </p>
        <p className="education__text">
          Create a transparent and efficient application and disbursement
          process, ensuring accessibility and equal opportunities for students
          from diverse backgrounds.
        </p>
        <p className="education__text">
          Implementing a student loan program can help address the financial
          barriers that prevent some students from accessing higher education.
          It provides them with the means to pursue their studies and alleviates
          the burden of upfront tuition fees. By including student loan programs
          in the education policy, the Labour Party Lagos aims to promote
          equitable access to tertiary education and empower students to achieve
          their educational aspirations.
        </p>
        <p className="education__text">
          By implementing these solutions, the Labour Party Lagos aims to
          address the challenges faced by state-owned universities and
          polytechnics in Lagos, ensuring access to quality education, research
          excellence, and industry relevance.
        </p>
        <div className="education__wrapper">
          <SiPinboard className="education__icon education__icon--pin" />
          <h2 className="education__subtitle education__subtitle--main">
            Conclusion
          </h2>
        </div>
        <p className="education__text">
          The proposed education policy by the Labour Party Lagos is aimed at
          improving access to quality education, enhancing curriculum
          development, empowering teachers, upgrading infrastructure,
          integrating educational technology, fostering collaboration, ensuring
          access to quality education, research excellence, and industry
          relevance, and ensuring effective monitoring and evaluation. By
          implementing this comprehensive education policy, the Labour Party
          Lagos aims to create a conducive and inclusive learning environment
          that prepares students for the challenges of the future, empowers them
          with the necessary skills and knowledge, and enables them to
          contribute meaningfully to society and the economy.
        </p>
        <p className="education__text">
          It is essential to recognize that education is a long-term investment
          and requires consistent commitment and allocation of resources. The
          Labour Party Lagos is committed to working collaboratively with all
          stakeholders to implement and refine this education policy, ensuring
          that it is responsive to the evolving needs of students, teachers, and
          the community.
        </p>
        <p className="education__text">
          By prioritizing education as a fundamental right and a catalyst for
          social and economic progress, the Labour Party Lagos seeks to build a
          prosperous and inclusive Lagos where every child has an equal
          opportunity to thrive and succeed. Through this education policy, we
          envision a future where education becomes a transformative force,
          unlocking the full potential of individuals and fostering a
          knowledge-driven society.
        </p>
        <p className="education__text">
          The Labour Party Lagos is dedicated to promoting an education system
          that instills values of equity, excellence, creativity, and critical
          thinking, enabling individuals to become responsible citizens and
          leaders in their respective fields. By investing in education today,
          we lay the foundation for a brighter and more prosperous future for
          Lagos and its people.
        </p>
      </div>
    </div>
  );
};

export default Education;
