import { createSlice } from "@reduxjs/toolkit";

const initialState = { form: {} };

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    updateForm(state, action) {
      state.form = action.payload;
    },
  },
});

export const registrationSelectForm = (state) => state.registration.form;
export const { updateForm } = registrationSlice.actions;
export default registrationSlice.reducer;
