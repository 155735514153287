import { configureStore } from "@reduxjs/toolkit";
import registrationReducer from "../features/registrationSlice";
import donationReducer from "../features/donationSlice";
import appReducer from "../features/appSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    registration: registrationReducer,
    donation: donationReducer,
  },
});
