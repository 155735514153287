import { createSlice } from "@reduxjs/toolkit";

const initialState = { amount: "50000", name: "" };

const donationSlice = createSlice({
  name: "donation",
  initialState,
  reducers: {
    updateDonationAmount(state, action) {
      if (!action.payload) {
        state.amount = "100000";
      } else {
        state.amount = `${parseInt(action.payload) * 100}`;
      }
    },
  },
});

export const donationSelectAmount = (state) => state.donation.amount;
export const { updateDonationAmount } = donationSlice.actions;
export default donationSlice.reducer;
