import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { thanks } from "../asset";
import {
  registrationSelectForm,
  updateForm,
} from "../features/registrationSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { url } from "../constants/variables";

export const Extra = () => {
  const member = useSelector(registrationSelectForm);
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    axios
      .get(`${url}/user/${member.partyNo}`)
      .then((res) => {
        dispatch(updateForm(res.data));
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  return (
    <div>
      <Header />
      <div className="flex items-center justify-center flex-col ">
        <img
          src={member.image}
          className="w-3/4 md:w-1/4 rounded my-8"
          alt=""
        />
        <h2 className=" text-center mx-4 text-2xl font-semibold">
          Welcome to the winning team. 💯 🚀
        </h2>
        <article className="shadow-xl rounded-lg py-4 bg-white flex flex-col w-[90%] sm:w-2/3 px-8 sm:px-20 my-8">
          <h2 className="text-center font-semibold text-gray-500 capitalize text-xl">
            your details are below
          </h2>
          {member.partyNo && (
            <div className="flex items-center justify-between w-full m-3">
              <h2 className="font-bold capitalize ">Membership ID: </h2>
              <p className="text-gray-400 font-semibold text-sm">
                {member?.partyNo}
                {member.payment !== "paystack" && (
                  <i
                    style={{
                      color: "orange",
                      fontSize: "10px",
                      display: "block",
                    }}
                  >
                    Pending activation...
                  </i>
                )}
              </p>
            </div>
          )}
          <div className="flex items-center justify-between w-full m-3">
            <h2 className="font-bold capitalize ">name: </h2>
            <p className="text-gray-400 font-semibold text-sm">
              {member?.name}
            </p>
          </div>
          <div className="flex items-center justify-between w-full m-3">
            <h2 className="font-bold capitalize ">phone number: </h2>
            <p className="text-gray-400 font-semibold text-sm">
              {member?.phone}
            </p>
          </div>
          <div className="flex items-center justify-between w-full m-3">
            <h2 className="font-bold capitalize ">address: </h2>
            <p
              className="text-gray-400 font-semibold text-sm"
              style={{ marginLeft: "10px" }}
            >
              {member?.address}
            </p>
          </div>
          <div className="flex items-center justify-between w-full m-3">
            <h2 className="font-bold capitalize ">LGA: </h2>
            <p className="text-gray-400 font-semibold text-sm">{member?.lga}</p>
          </div>
          <div className="flex items-center justify-between w-full m-3">
            <h2 className="font-bold capitalize ">ward: </h2>
            <p className="text-gray-400 font-semibold text-sm">
              {member?.ward}
            </p>
          </div>
          <div className="flex items-center justify-between w-full m-3">
            <h2 className="font-bold capitalize ">sex: </h2>
            <p className="text-gray-400 font-semibold text-sm">{member?.sex}</p>
          </div>
        </article>
      </div>
      <Footer />
    </div>
  );
};
