import React from "react";

const Hero1 = ({ bg, title, subtitle }) => {
  return (
    <div className="hero1">
      <div className="hero1__frame">
        <img className="hero1__bg" src={bg} alt="" />
        {/* {title && <p className="hero1__title">{title}</p>}
        {subtitle && <p className="hero1__subtitle">{subtitle}</p>} */}
      </div>
    </div>
  );
};

export default Hero1;
