import React from "react";
import { MdLocationPin } from "react-icons/md";
import { BsFillPersonVcardFill, BsTicketPerforated } from "react-icons/bs";
import { HiOutlineFingerPrint } from "react-icons/hi";
import { MdHowToVote } from "react-icons/md";
import { GiVote } from "react-icons/gi";

export const MovementCard = () => {
  const data = [
    {
      id: 1,
      icon: <MdLocationPin />,
      title: "Complete your online membership registration",
    },
    {
      id: 2,
      icon: <BsFillPersonVcardFill />,
      title: "Collect your membership card at your Ward",
    },
    {
      id: 3,
      icon: <HiOutlineFingerPrint />,
      title: "Pay your monthly dues",
    },
    {
      id: 4,
      icon: <BsTicketPerforated />,
      title: "Participate actively at Ward, LGA and State events",
    },
    {
      id: 5,
      icon: <MdHowToVote />,
      title: "Know the party constitution",
    },
    {
      id: 6,
      icon: <GiVote />,
      title: "Vote for Labour Party in all elections",
    },
  ];
  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      {data.map((item) => (
        <div
          key={item.id}
          className=" flex items-center flex-col space-y-4 m-2"
        >
          <div className="bg-gray-100 rounded-full flex items-center justify-center text-gray-500 hover:bg-gray-500 hover:text-white w-[70px] h-[70px]  text-4xl">
            {item.icon}
          </div>
          <h2 className="text-gray-800 text-xl">step {item.id}</h2>
          <p className="text-center">{item.title}</p>
        </div>
      ))}
    </div>
  );
};
